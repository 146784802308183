import {
  getField,
  updateField
} from "vuex-map-fields";

// @FONTS
// --- Arial
const ArialNarrow = require('@/assets/fonts/arial-narrow/ARIALN.TTF');
const ArialNarrowBold = require('@/assets/fonts/arial-narrow/ARIALNB.TTF');
const ArialNarrowBoldItalic = require('@/assets/fonts/arial-narrow/Arialnbi.ttf');
const ArialNarrowItalic = require('@/assets/fonts/arial-narrow/ARIALNI.TTF');

// --- LiberationSans
const LiberationSans = require('@/assets/fonts/LiberationSans/LiberationSans.ttf');
const LiberationSansBold = require('@/assets/fonts/LiberationSans/LiberationSansBold.ttf');
const LiberationSansBoldItalic = require('@/assets/fonts/LiberationSans/LiberationSansBoldItalic.ttf');
const LiberationSansItalic = require('@/assets/fonts/LiberationSans/LiberationSansItalic.ttf');

// --- PlayFair
const PlayFair = require('@/assets/fonts/playfair/Playfair.ttf');
const PlayFairBold = require('@/assets/fonts/playfair/PlayfairBold.ttf');
const PlayFairBoldItalic = require('@/assets/fonts/playfair/PlayfairBoldItalic.ttf');
const PlayFairItalic = require('@/assets/fonts/playfair/PlayfairItalic.ttf');

// --- Roboto
const Roboto = require('@/assets/fonts/roboto/Roboto.ttf');
const RobotoBold = require('@/assets/fonts/roboto/RobotoBold.ttf');
const RobotoBoldItalic = require('@/assets/fonts/roboto/RobotoBoldItalic.ttf');
const RobotoItalic = require('@/assets/fonts/roboto/RobotoItalic.ttf');

// just an object with common data that will use all templates
const initialData = {
  font: 'ArialNarrow',
  logo: {
    url: '',
    url2: [],
    ranger: 50,
    positionX: 0,
    positionY: 0,
  },
  biller: {
    invoiceAddress: {
      content: `Schwarzenbach KMU Services AG\nKirchgasse 26, CH-8001 Zürich`,
      size: 10,
      lineHeight: 12,
    },
    contactDetails: {
      content: `www.qrmodul.ch\nTelefon 012 345 67 89\nCHE-123.456.789 MWST\nKontakt für Fragen: info@qrmodul.ch`,
      fontType: '',
      size: 10,
      lineHeight: 12,
    },
  },
  customerSection: {
    content: '[Company or first name + last name]\n[Address suffix]\n[street + street no.]\n[ZIP + city]',
    size: 11,
    lineHeight: 12,
  },
  centerSection: {
    subject: {
      content: 'Rechnung',
      bold: true,
      size: 14,
    },
    customerFullName: { // SALUTATION + LAST NAME
      content: 'Sehr geehrter Herr Muster',
      size: 11,
    },
    textLine: {
      content: 'Für die Nutzung von qrmodul.ch stellen wir Ihnen folgende Leistungen in Rechnung:',
      size: 11
    },
  },
  invoiceDetails: {
    location: {
      content: 'Zürich',
      size: 11,
    },
    date: {
      content: '5. Marz, 2021',
      fontType: '',
      size: 11,
    },
    headers: {
      invoiceNumber: {
        content: 'Invoice Number',
        size: 10,
      },
      customerNumber: {
        content: 'Customer Number',
        size: 10,
      },
      payableUntil: {
        content: 'Payable Until',
        size: 10,
      }
    },
    values: {
      invoiceNumber: {
        content: '[Quelle: Debitorenliste]',
        size: 10,
      },
      customerNumber: {
        content: '[Quelle: Debitorenliste]',
        size: 10,
      },
      payableUntil: {
        content: '[Quelle: Prozess QR-Rechnungen in Serie]',
        size: 10,
      }
    }
  },
  itemsSection: {
    service: {
      content: 'Service',
      italic: true,
      bold: true,
      size: 8,
    },
    quantity: {
      content: 'Quantity',
      italic: true,
      bold: true,
      size: 8,
    },
    unitPrice: {
      content: 'Unit Price',
      italic: true,
      bold: true,
      size: 8,
    },
    vatPercent: {
      content: 'VAT %',
      italic: true,
      bold: true,
      size: 8,
    },
    vat: {
      content: 'VAT',
      italic: true,
      bold: true,
      size: 8,
    },
    total: {
      content: 'Total',
      italic: true,
      bold: true,
      size: 8,
    },
  },
  items: [
    {
      item      : '[Produkt 1 / Quelle: Debitorenliste]',
      quantity  : '1',
      prize     : `0'000.0`,
      vatPercent: '7.70',
      vat       : `0'000.0`,
      total     : `0'000.0`,
    },
    {
      item      : '[Produkt 2 / Quelle: Debitorenliste]',
      quantity  : `1`,
      prize     : `0'000.0`,
      vatPercent: '7.70',
      vat       : `0'000.0`,
      total     : `0'000.0`,
    },
    {
      item      : '[Produkt 3 / Quelle: Debitorenliste]',
      quantity  : `1`,
      prize     : `0'000.0`,
      vatPercent: '7.70',
      vat       : `0'000.0`,
      total     : `0'000.0`,
    }
  ],
  paymentDetails: {
    headers: {
      invoiceAmount: {
        content: 'Invoice Amount',
        bold: true,
        size: 10,
      },
      subTotal: {
        content: 'Sub Total',
        size: 10,
      },
      vat: {
        content: 'VAT',
        size: 10,
      },
      total: {
        content: 'Total',
        size: 10,
        bold: true,
      },
    },
    values: {
      subTotal: {
        content: `0'000'000.00`,
        size: 10,
      },
      vat: {
        content: `0'000'000.00`,
        size: 10,
      },
      total: {
        content: `0'000'000.00`,
        size: 10,
        bold: true,
      },
      currency: {
        content: 'CHF',
        size: 10,
        bold: true,
      }
    },
  },
  commentsSection: {
    textLine: {
      content: 'Besten Dank für die Überweisung des Rechnungsbetrages mit beiliegendem QR-Zahlteil innert 30 Tagen.\nTextzeile 2.\n',
      size: 12,
      lineHeight: 12,
    }
  },
  QRPlacement: null,
}

const templateEditor = {
  namespaced: true,
  state: {
    isDefault:false,
    flagValidation:false,
    flagValidationBreakLine:false,
    fields: initialData,
    template_name: '',
    PDFDate: '',
    logoData: {
      xDiff: 0,
      yDiff: 0,
      imageHeight: 0,
      imageWidth: 0,
      maxWidth: 0,
      maxHeight: 0,
    },
    templateType: '',
    dummyInfo: {
      biller: {
        invoiceAddress: `Schwarzenbach KMU Services AG\nKirchgasse 26, CH-8001 Zürich`,
        contactDetails: `www.qrmodul.ch\nTelefon 012 345 67 89\nCHE-123.456.789 MWST\nKontakt für Fragen: info@qrmodul.ch`,
      },
      centerSection: {
        subject: 'Rechnung',
        customerFullName: 'Sehr geehrter Herr Muster',
        textLine: 'Für die Nutzung von qrmodul.ch stellen wir Ihnen folgende Leistungen in Rechnung:',
      },
      invoiceDetails: {
        date: '5. Marz, 2021',
        headers: {
          invoiceNumber: 'Invoice Number',
          customerNumber: 'Customer Number',
          payableUntil: 'Payable Until',
        },
        values: {
          invoiceNumber: '0102913',
          customerNumber: 'CO0201022',
          payableUntil: '0102913',
        }
      },
      itemsSection: {
        service: {
          content: 'Service',
          italic: true,
        },
        quantity: 'Quantity',
        unitPrice: 'Unit Price',
        vatPercent: 'VAT %',
        vat: 'VAT',
        total: 'Total',
      },
      paymentDetails: {
        headers: {
          invoiceAmount: 'Invoice Amount',
          subTotal: 'Sub Total',
          vat: 'VAT',
          total: 'Total',
          fontType: '',
        },
        values: {
          subTotal: '10912,03',
          vat: '8311,93',
          total: '93919',
          currency: 'CHF'
        },
      },
    },
    fonts: {
      // font + Bold
      // font + Italic
      // font + Bold + Italic
      ArialNarrow,
      ArialNarrowBold,
      ArialNarrowBoldItalic,
      ArialNarrowItalic,

      PlayFair,
      PlayFairBold,
      PlayFairBoldItalic,
      PlayFairItalic,

      Roboto,
      RobotoBold,
      RobotoBoldItalic,
      RobotoItalic,

      LiberationSans,
      LiberationSansBold,
      LiberationSansBoldItalic,
      LiberationSansItalic
    },
    debitorsData: [],
    debtorSelected: null,
    renderQR: true,
    reset: function() { // this method will reset all the object property "fields"
      if(Object.keys(this).includes('fields')) {
        this.fields.font = 'ArialNarrow';
        this.template_name = '';
        this.fields.logo = {
          url: '',
          width: 100,
          height: 100,
          positionX: 0,
          positionY: 0,
          ranger: 50,
        };
        this.fields.centerSection = {
          subject: {
            content: 'Rechnung',
            bold: true,
            size: 14,
          },
          customerFullName: { // SALUTATION + LAST NAME
            content: 'Sehr geehrter Herr Muster',
            bold: true,
            size: 11,
          },
          textLine: {
            content: 'Für die Nutzung von qrmodul.ch stellen wir Ihnen folgende Leistungen in Rechnung:',
            size: 11
          },
        }
        this.fields.commentsSection = {
          textLine: {
            content: 'Besten Dank für die Überweisung des Rechnungsbetrages mit beiliegendem QR-Zahlteil innert 30 Tagen.\nTextzeile 2.\n',
            size: 12,
            lineHeight: 12,
          }
        };
        this.fields.invoiceDetails = {
          ...this.fields.invoiceDetails,
          location: {
            content: 'Zürich',
            size: 11,
          }
        }
      }
    },
    resetLogo: function() {
      if(Object.keys(this).includes('fields')) {
        this.fields.logo = {
          url: '',
          width: 100,
          height: 100,
          positionX: 0,
          positionY: 0,
          ranger: 50,
        }
      }
    }
  },
  mutations: {
    updateField,
    setTemplate(state, payload) {
      const {
        font,
        greeting,
        contactDetails,
        currency,
        closing,
        subject,
        template_name
      } = payload;
      
      state.fields.font                             = font;
      state.fields.centerSection.textLine.content   = greeting;
      state.fields.centerSection.subject.content    = subject;
      state.fields.biller.contactDetails.content    = contactDetails;
      state.fields.commentsSection.textLine.content = closing;
      state.template_name                           = template_name;

    },
    setLogo(state, payload) {
      const { data, img } = payload;
      const { height, width, ranger, positionX, positionY } = data;
      const { logo64, extension } = img

      Object.assign(state.fields.logo, {
        width,
        height,
        url: logo64,
        type: extension,
        ranger,
        positionX,
        positionY,
      })
    },
    reinitializeData(state, payload) {
      state.reset();
    },
    reinitializeLogo(state, payload) {
      state.resetLogo();
    },
    setInvoiceAddress(state, {
      company,
      first_name,
      last_name,
      street,
      street_number,
      country,
      zip,
      city,
      legal_form
    }) {
      let companyArray = [
        'Private person',
        'Self-employed person(s)',
        'Sole proprietorship'
      ];
      let isCompany = !companyArray.find(element => element === legal_form);
      //let calculatedName = isCompany ? `${company}` : `${first_name}${last_name}`;
      
      // SetInvoiceAdress is the one that handles the receipt.
      // The company was left concatenated with the name, since there is an error that if 
      // you write the company, even if you write your name/surname, it will not be taken.
      let calculatedName = '';
      
      if( (first_name != null || first_name != '') && (last_name != null || last_name != '') ){
        calculatedName = `${company}\n${first_name} ${last_name}`;
      }

      if( (first_name == null || first_name == '') && (last_name == null || last_name == '') ){
        calculatedName = `${company}`;
      }
      
      if( first_name == null || first_name == '' ){
        calculatedName = `${company}\n${last_name}`;
      }

      if( last_name == null || last_name == '' ){
        calculatedName = `${company}\n${first_name}`;
      }

      // This is the method that already shows the information on the receipt.
      state.fields.biller.invoiceAddress.content = 
      `
${`${calculatedName}`}
${street} ${street_number}\n${country}-${zip} ${city}
      `.trim();

      state.fields.invoiceDetails.location.content = city;
    },
    setDebtor(state, payload) {
      state.fields.customerSection.content = payload;
    },
    setRenderQR(state, payload) {
      state.renderQR = payload;
    },
    setFlagValidationCenterSectionMessage(state,payload){
          state.flagValidation = payload;
    },
    setFlagValidationBreakLine(state,payload){
          state.flagValidationBreakLine = payload;
    },
    setFont(state,payload){
       state.fields.font = payload;
    }
  },
  getters: {
    getField,
    fields: state => {
      return {
        ...state.fields,
        template_name: state.template_name
      }
    },
    fonts: state => state.fonts,
    debitor: state => {
      return state.debitorsData.find(({
        id
      }) => id === state.debtorSelected)
    },
    dummyInfo: state => state.dummyInfo,
    cityAndDate: state => {
      const city = state.fields.invoiceDetails.location.content;
      const date = state.fields.invoiceDetails.date.content
      return {
        content: `${city} ${date}`,
        size: 11,
      }
    },
    renderQR: state => state.renderQR,
    flagValidation: state =>  state.flagValidation,
    flagValidationBreakLine : state => state.flagValidationBreakLine
  },
}

export default templateEditor;