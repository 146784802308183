<template>
    <v-row>
      <v-col cols="3">
        <v-form ref="controlsForm" >
          <controls 
            v-if="canRenderEditor" 
            :deleteLogoButton="deleteLogoButton"
            @getLogo="getLogo"
            @update-font="updateFont"
          />
        </v-form>
      </v-col>
      <v-col cols="9">
        <template-viewer 
          v-if="canRenderViewer" 
          :idTemplate="idTemplate" 
          :renderQR="renderQR"
          :logo="fields.logo"
          :subject="subject"
          :presentation="presentation"
          :contactDetails="contactDetails"
          :bottomComment="bottomComment"
          :myFont="myFont"
          showDummyItems
        />
      </v-col>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import templateEditorControlsVue from './editor.controls.vue';
import templateEditorTemplateVue from './editor.template.vue';

export default {
  components: {
    'template-viewer': templateEditorTemplateVue,
    'controls': templateEditorControlsVue,
  },
  data() {
    return {
      canRenderViewer: false,
      canRenderEditor: false,
      deleteLogoButton: false,
      myFont: 'ArialNarrow',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'templateEditor/fields'
    }),
    subject() {
      return this.fields.centerSection.subject.content;
    },
    presentation() {
      return this.fields.centerSection.textLine.content;
    },
    contactDetails() {
      return this.fields.biller.contactDetails.content;
    },
    bottomComment() {
      return this.fields.commentsSection.textLine.content;
    },
  },
  async mounted() {
    this.$nextTick(async function() {
      if(this.idTemplate) {
        this.deleteLogoButton = true;
      }
      
      this.canRenderViewer = true;
      this.canRenderEditor = true;
    })
  },
  beforeDestroy() {
    this.$store.commit('templateEditor/reinitializeData', {})
  },
  methods: {
    updateViewer() {
      this.iViewer++;
    },
    getLogo(logo) {
      console.log(logo);
    },
    updateFont(font) {
      this.myFont = font;
      this.$store.state.templateEditor.font = font;
    }
  },
  props: {
    idTemplate: {},
    renderQR: {
      type: Boolean,
      default: true,
    },
    showDummyItems: {
      type: Boolean, 
      default: false,
    }
  },
};

</script>

<style scoped>

</style>
