<template>
  <v-row>
    <v-col cols="12">
      <v-stepper v-model="e1" alt-labels>
        <v-stepper-header class="elevation-0">
          <v-stepper-step :complete="e1 > 1" step="1">
            {{ $t("QrSeries.step_one.subtitle_stepone") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">
            {{ $t("QrSeries.step_two.subtitle_steptwo") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3">
            {{ $t("QrSeries.step_three.subtitle_stepthree") }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 4" step="4">
            {{ $t("QrSeries.step_four.subtitle_stepfour") }}
          </v-stepper-step>
          <v-divider></v-divider>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div v-show="e1 > 0">
              <div class="ma-5">
                <qr-series-step1 
                  ref="qrseries1"
                  @update-template="idTemplate = $event"
                ></qr-series-step1>
              </div>
              <div class="pt-15">
                <v-btn color="primary" @click="step1()">
                  {{ $t("QrSeries.step_one.btn_continue") }}
                </v-btn>
                <v-btn text @click="cancelStep1()">
                  {{ $t("QrSeries.step_one.btn_cancel") }}
                </v-btn>
              </div>
            </div>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div v-if="e1 > 1">
              <v-row>
                <v-col>
                  <v-btn color="primary" @click="step2">{{ $t("QrSeries.step_one.btn_continue") }}</v-btn>
                  <v-btn text @click="cancelStep2">{{ $t("QrSeries.step_one.btn_cancel") }}</v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <qr-massive-debtor 
                    ref="qrMassiveDebtor"
                    @set-checkbox-address="setCheckboxAddress"
                    @set-checkbox-reference="setCheckboxReference"
                    @set-checkbox-amount="setCheckboxAmount"
                    @set-checkbox-message="setCheckboxMessage"
                    @set-checkbox-billing="setCheckboxBilling"
                    @set-checkbox-addData="setCheckboxAddData"
                  ></qr-massive-debtor>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3">
            <div v-show="e1 > 2">
              <v-row no-gutters>
                <v-col>
                  <v-btn color="primary" @click="step3">
                    {{ $t("QrSeries.step_one.btn_continue") }}
                  </v-btn>
                  <v-btn text @click="cancelStep3()">
                    {{ $t("QrSeries.step_one.btn_cancel") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <qr-massive-step 
                    ref="qrMassiveStep"
                    :debtors="debtorsFail"
                    :idTemplate="idTemplate"
                  ></qr-massive-step>
                </v-col>
              </v-row>
            </div>
          </v-stepper-content>
          <v-stepper-content step="4">
            <div v-show="e1 > 3">
              <qr-final-step ref="finalStep"
                :debtors="debtors"
                :checkboxAddress="checkboxAddress"
                :checkboxAmount="checkboxAmount"
                :checkboxBilling="checkboxBilling"
                :checkboxMessage="checkboxMessage"
                :checkboxReference="checkboxReference"
                :checkboxAddData="checkboxAddData"
                :idTemplate="idTemplate"
              ></qr-final-step>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-col>
    <window-credits
      :key="updateBuyCredits"
      v-bind:propsMap="{
        popup: popup,
        price: price,
        origin: 'series',
        creditsinsuf: creditsinsuf,
        titlepopup: titlepopup,
        subtitlepopup: subtitlepopup,
        remainingcredits: remainingcredits,
        total: total
      }"
    ></window-credits>
    <v-dialog   hide-overlay transition="dialog-bottom-transition" height="200" width="300" v-model="snackbarsuccess">
      <v-card justify="center" flat>
        <v-card-title  class="justify-center" > <h3>{{$t("snack_confirm.success")}}!</h3></v-card-title>
        <v-card-text >  
          <div  class="d-flex justify-center">
            <v-img
              max-width="200"
              max-height="300"
              :src="images.logo"
            >
            <div class="fill-height repeating-gradient"></div>
            </v-img>
          </div> 
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import qrseries1 from "@/components/cockpit/QR-Massive-Steps/qr.series.step1.vue";
import debtorStep from "@/components/cockpit/QR-Massive-Steps/DebtorStep.vue";
import massiveStep from "@/components/cockpit/QR-Massive-Steps/ValidationsStep.vue";
import finalstep from "@/components/cockpit/QR-Massive-Steps/FinalStep.vue";
import WindowCredits from "../../globals/WindowCredits.vue";
// Reference Type Detector
const detector = require("../../../detectTypeReference.js");
import { QRR } from "@/functions/reference";
import ISO11649 from "iso-11649";
import {validateCharac} from "../../../functions/invalidCharacters";


import { mapGetters } from "vuex";
import {
  address,
  debitorFullName,
  zip,
  nameMissingZip,
  debitorCountryRules,
  city,
  fieldRequired,
  productsRequired,
  languages,
  invoiceDateRequired
} from "@/components/cockpit/QR-Massive-Steps/rules";
export default {
  components: {
    "qr-series-step1": qrseries1,
    "qr-massive-debtor": debtorStep,
    "qr-massive-step": massiveStep,
    "qr-final-step": finalstep,
    WindowCredits
  },
  data() {
    return {
      images: {
        logo: require('../../../../../assets/images/default/qrLogoComplete.png')
      },
      e1: 1,
      updateBuyCredits: 0,
      creditsinsuf: false,
      titlepopup: "",
      subtitlepopup: "",
      popup: false,
      price: "",
      total: "",
      membership: "",
      creditsused: "",
      membcredits: "",
      remainingcredits: "",
      debtors:[],
      // OrderID:"",
      SelectedCustom: "",
      donationSelected: "",
      payableSelected: "",
      checkboxAddress: true,
      checkboxReference: true,
      checkboxAmount: true,
      checkboxMessage: true,
      checkboxBilling: false,
      checkboxAddData:false,
      templateType: "",
      ProfileSelected: "standard",
      SelectionIntegration: "",
      createComplete: "",
      idTemplate: null,
      snackbarsuccess: false
    };
  },
  computed: {
    locale(){
      return this.$store.state.User.language;
    },
    ...mapGetters({
      creditor: 'User/creditor',
    }),
    debtorsFail: {
      get() {
        return this.$store.state.QR.debtorsFail;
      },
      set(value) {
        this.$store.state.QR.debtorsFail = value;
      },
    },

    userHasWir(){
        return this.$store.state.User.wir_active;
    },

    typeOfProfile: {
      get() {
        return this.$store.state.qrSeries.typeOfProfile
      },
      set(value) {
        this.$store.state.qrSeries.typeOfProfile = value;
      }
    },
    profiles() {
      return this.$store.state.qrSeries.profiles
    },
    reference:{
      get(){
        return this.$store.state.QR.reference;
      },
      set(value){
        return this.$store.state.QR.reference = value;
      }
    },
    payable_until: {
      get() {
        return this.$store.state.QR.payable_until;
      },
      set(value) {
        this.$store.state.QR.payable_until = value;
      },
    },
    typeOfReference:{
      get(){
        return this.$store.state.User.creditor.typeReference;
      },
      set(value){
        return this.$store.state.User.creditor.typeReference = value;
      }
    },
    invoice_date: {
      get() {
        return this.$store.state.QR.invoice_date;
      },
      set(value) {
        this.$store.state.QR.invoice_date = value;
      },
    },  
    unstructuredMessage: {
      get() {
          return this.$store.state.AI.unstructuredMessage;
      },
      set(message) {
          this.$store.commit("AI/unstructuredMessage", {
              unstructuredMessage: message
          });
      }
    }   
  },
  watch:{
    typeOfReference(newToR,oldToR){
      if(newToR === 'QRR'){
        if(this.userHasWir){
             this.creditor.accountWir = this.creditor.qrIBAN_wir;
             this.creditor.account = this.creditor.qrIBAN;
        }else{
             this.creditor.account = this.creditor.qrIBAN;
        }
      }else{
        if(this.userHasWir){
            this.creditor.accountWir = this.creditor.qr_account_wir;
            this.creditor.account = this.creditor.qr_account;
        }else{
             this.creditor.account = this.creditor.qr_account;
        }
      }
    },    
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t("QrSeries.main_title"))
    }
  },
  mounted() {
    //This commit changes the title on the dashboard
    this.$store.commit("global/setPageTitle", this.$t("QrSeries.main_title"));
  },
  beforeDestroy() {
    this.invoice_date = '';
    this.unstructuredMessage = ''
  },
  methods: {
    async step1() {
      this.setProfileToStore();
      const StepOne = this.$refs.qrseries1.$refs.form.validate();
      if (!StepOne) {
        // this.DOMElements.continue.loading = false;
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("snack_confirm.discover_error"),
        });
      } else {
        this.e1 = 2;
      }
    },

    async setProfileToStore() {
      const profile = this.$store.getters['qrSeries/getProfileData'];
      if(profile){
        if(Object.keys(profile).length > 0) {
          
          let getTypeReference = await detector.detectTypeReference(profile.reference);
                  
          this.creditor.account          = profile.accountNumber || '';
          this.creditor.accountWir       = profile.accountNumberWir || ''; 
        
          this.creditor.qrIBAN           =  profile.accountQRNumber || '';
          this.creditor.qrIBAN_wir       =  profile.accountQRNumberWir || '';
          
          this.creditor.qr_account       =  profile.accountNumber || '';
          this.creditor.qr_account_wir   =  profile.accountNumberWir || '';

          this.creditor.name            = profile.firstName;
          this.creditor.last_name       = profile.lastName;
          this.creditor.address.street  = profile.street;
          this.creditor.address.number  = profile.houseNumber;
          this.creditor.address.zip     = profile.zipCode;
          this.creditor.address.city    = profile.city;
          this.creditor.address.country = profile.selectedCountry;
          this.creditor.typeReference   = getTypeReference;
          this.reference = getTypeReference !== 'NON' ? profile.reference : '';
        }
        
      }
    },

    cancelStep1() {
      this.$root
        .$confirm(
          this.$t("snack_confirm.cancel_title"),
          this.$t("snack_confirm.cancel_process")
        )
        .then((response) => {
          if (response) {
            Object.assign(this.$refs.qrseries1, {
              ProfileSelected: "standard",
              OtherProfileSelected: "",
              templateType: "",
              createComplete: "",
              SelectionIntegration: "",
              payableSelected: "",
              payableDate: "",
              templatesItems: [],
            });
            this.$router.push({name: "qr-table" });
          }
        });
    },
    async CreateInvoice(addExtra) {
      const templateID = this.$refs.qrseries1.createComplete;
      const templateType = this.$refs.qrseries1.templateType;
      const SelectionIntegration = this.$refs.qrseries1.SelectionIntegration;
      const Selected = this.$refs.qrMassiveDebtor.$refs.debtorTable.selected;
      const slipParts = this.$refs.qrMassiveDebtor.$data.debtorsChecks;
      const payableDate = this.$refs.qrseries1.payableDate;
      let profile = this.$store.getters['qrSeries/getProfileData'];
      let profileID = null;
    
      if(profile){
        profileID = profile.id !== undefined && profile.id !== null ? profile.id : null;
      }
      if( this.$refs.finalStep.BtnDownloadDark == true && this.$refs.finalStep.BtnDownload == false){
        this.$refs.finalStep.BtnDownloadDark = false;
        this.$refs.finalStep.BtnDownload = true ;
      }      
      let typeReferencesList = await [...new Set(Selected.map((element)=>detector.detectTypeReference(element.reference)))];
    
      //A request is made to the "/api/exampleQr" endpoint, to generate the invoice
      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/premium/createOrder`,
        {
          method: `POST`,
          body:JSON.stringify({
            profile:profileID,
            addExtra: addExtra,
            invoices: Selected.length,
            referenceTypes:typeReferencesList
          }),
          headers:{
            'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
          }
        }
      )
        .then(async (responseCreate) => {
          if (responseCreate.status == 200) {
            this.$store.state.qrSeries.OrderID = responseCreate.orderId;
            let data = {
              templateId: templateID,
              profileId: profileID,
              dataList: [],
              slipParts: slipParts,
              SelectionIntegration: SelectionIntegration,
              templateType: templateType
            };
            for (let i = 0; i < Selected.length; i++) {
              const sel = Selected[i];

              const billing = sel.billing_information;
              const currentProfile = profile;
              
              this.$store.commit('AI/setBillingInformation',  {
                bill_number: sel.invoice_no,
                customerReference: currentProfile.reference,
                voucherDate: billing.invoice_date,
                UID_Number: currentProfile.UIDNumber,
                VAT_Details: {
                    rate: billing.VAT,
                    amount: billing.discount,
                },
                VAT_Import: {
                    rate: billing.VAT,
                    amount: billing.discount,
                },
                VAT_Conditions: {
                    discount: billing.discount,
                    days: billing.payment_deadline
                }
              });
              
              const structuredMessage = this.$store.getters['AI/structuredMessage'].plain || '';
              const structuredMap = this.$store.getters['AI/structuredMap'];
              let dateObject = this.getInvoiceDates();
             
             
              let objInfo = {
                debitor: {
                  id: Selected[i].id_b ? Selected[i].id_b : '', 
                  name: Selected[i].name ? Selected[i].name : '',
                  last_name: Selected[i].last_name ? Selected[i].last_name : '',
                  address_suffix: Selected[i].address_suffix ? Selected[i].address_suffix : '',
                  street: Selected[i].street ? Selected[i].street : '',
                  number: Selected[i].number ? Selected[i].number : '',
                  zip: Selected[i].zip ? Selected[i].zip : '',
                  city: Selected[i].city ? Selected[i].city : '',
                  country: Selected[i].country ? Selected[i].country : '',
                  salutation: Selected[i].salutation ? Selected[i].salutation : '',
                  language: Selected[i].language ? Selected[i].language : '',
                },
                dateExpedition: billing.invoice_date || '',
                products: JSON.parse(Selected[i].products_json),
                invoiceNumber: Selected[i].billing_information.invoice_no,
                clientNumber: Selected[i].client_number,
                payBy: Selected[i].payable_until || payableDate,
                reference: Selected[i].reference,
                message: Selected[i].message,
               
                billing: structuredMessage,
                additionalInformation: structuredMap,
                invoiceDates: dateObject,
                billing_information: Selected[i].billing_information,
                amount: Selected[i].amount
              }

              if(Selected[i].currency == "CHF/CHW"){
                  objInfo.currency = "CHF";
              }else{
                  objInfo.currency = Selected[i].currency;
              }

              data.dataList.push(objInfo);
            
            }
            let numberofdebtors = 10;

            for (let i = 0; i < data.dataList.length; i += numberofdebtors) {
              let progress = await this.$fetch(
                `${process.env.VUE_APP_SERVER_URL}/api/premium/generateQRFromTemplate`,
                {
                  method: `POST`,
                  body: JSON.stringify({
                    slipParts: data.slipParts,
                    selectionIntegration: data.SelectionIntegration == 'insert' ? true : false,
                    templateType: data.templateType == 'complete' ? true : false,
                    profileId: Number(data.profileId),
                    templateId: Number(data.templateId),
                    generateSeparatePaymentPart: this.$store.state.qrSeries.optionGeneratePaymentPart == 'separate',
                    dataList: data.dataList.slice(
                      i,
                      Math.min(i + numberofdebtors, data.dataList.length)
                    ),
                    orderId: this.$store.state.qrSeries.OrderID,
                    lastBatch: i + numberofdebtors >= data.dataList.length ? true : false,
                    invoiceNumber: i
                  }),
                  headers:{
                    'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
                  }
                }
              );
              
              if (progress.status !== 200) {
                return this.$store.commit("global/setSnackbar", {
                  message: progress.message,
                });
              }
              else{
                this.$refs.finalStep.$refs.progressBar.progressBarValue =  Math.min(i+numberofdebtors,data.dataList.length)*100 / data.dataList.length
              }


              let indexElement = -1;
              for(let i = 0 ; i < progress.debitorFiles.length ; i++){ 
                  
                  indexElement =  this.debtors.findIndex( element => element.id_b == progress.debitorFiles[i].id_debitor )
                  if(indexElement != -1){
                      this.debtors[indexElement].qrFileName = progress.debitorFiles[i].file;
                  }  
              }
              

              if (i + numberofdebtors >= data.dataList.length)
              {
                this.$store.state.qrSeries.LUCCToken = progress.LUCC_token;
              }
            }
              this.$refs.finalStep.$refs.progressBar.showProgressBar = false
              this.snackbarsuccess = true;
              this.$refs.finalStep.BtnDownloadDark=true;
              this.$refs.finalStep.BtnDownload=false 

          } 
          else{
            this.$refs.finalStep.$refs.progressBar.showProgressBar = false;
            return this.$store.commit("global/setSnackbar", {
              message: "Unable to register the order, please try again.",
            });
          }
        })
        .catch((err) => {
          if (err.status == 403){
            // this.dialog = true;
            // this.updateBuyCredits += 1
            this.$refs.finalStep.$refs.progressBar.showProgressBar = false
            this.price = err.response.data.price;
            this.total = err.response.data.total;
            this.membership = err.response.data.membership;
            this.creditsused = err.response.data.creditsUsed;
            this.membcredits = err.response.data.membershipCredits;
            this.remainingcredits = err.response.data.membershipCredits - err.response.data.creditsUsed;
            //if membership is single tariff is going to show a dialog
            if (this.remainingcredits <= 0 || this.membership == 5) {
              this.popup = true;
              this.titlepopup = this.$t(
                "QrSeries.step_four.insufficient_invoices.title"
              );
              this.subtitlepopup = this.$t(
                "QrSeries.step_four.insufficient_invoices.body"
              );
              this.updateBuyCredits += 1;
            }
          }
          else{
            this.$refs.finalStep.$refs.progressBar.showProgressBar = false
            return this.$store.commit("global/setSnackbar", { message: err });
          }
        });
    },

    checkArrayNotNulls(array){


       if(array && array.length <= 0)
          return true;
       else if (!array)
          return true;



        for(let i = 0; i < array.length ; i++ ){
             if(array[i] == null || array[i] == undefined || array[i] == ''){
                  return true;
             }
        }

        return false;

    },

    async step2() {
      
      this.debtors = this.$store.getters["Debtor/debtors_selected"];

      // Create a validation array that is going to have all the information
      // from the selected debtors to generate the QR bills
      let validationArray = [];

      // Search for every debtor selected on the debtor table and add
      // the info on the array
      for (let debtorActual of this.debtors){
        
        validationArray.push(debtorActual.name);
        validationArray.push(debtorActual.last_name);
        validationArray.push(debtorActual.address_suffix);
        validationArray.push(debtorActual.street);
        validationArray.push(debtorActual.number);
        validationArray.push(debtorActual.zip);
        validationArray.push(debtorActual.city);
        validationArray.push(debtorActual.message);
        validationArray.push(debtorActual.client_number);
        validationArray.push(debtorActual.qr_status);
        validationArray.push(debtorActual.billing_information.invoice_no);
        validationArray.push(debtorActual.billing_information.client_reference);

        let debtorProducts = JSON.parse(debtorActual.products_json);

        // Now search all the products name of the debtor(s) selected and
        // add id on the array
        for(let productActual of debtorProducts){
          validationArray.push(productActual.name);
        }
      }

      // Validate if the debtor(s) selected have a invalid character, if that is the case
      // don't allow the user to create the QR
      if( validateCharac(validationArray) ){
        return this.$store.commit('global/setSnackbar',{message:this.$t("Globals.error_character")});
      }

      if (this.debtors.length <= 0) {
        return this.$store.commit("global/setSnackbar", "At least 1 debtor must be selected");
      }
      if(this.debtors.length > 500){
        return this.$store.commit("global/setSnackbar", `${this.$t('QrSeries.step_two.rules.max_500_records')}`);
      }

      this.$nextTick(async() => {
        this.debtorsFail = [];
        for(let i = 0; i < this.debtors.length; i++){
          
          // Verify if the user have a product with a incorrect wir (Less than 0 or more than 100)
          let products = JSON.parse(this.debtors[i].products_json);
          
          for (let productIndex of products) {
            if(productIndex.percentage_wir < 0 || productIndex.percentage_wir > 100){
              return this.$store.commit("global/setSnackbar", `${this.$t('QrSeries.step_two.rules.incorrect_wir')}`);
            }
          }

          let currentDebtor = this.debtors[i];
          let calculatePU = this.payable_until ? this.payable_until : null;
          currentDebtor.payable_until = calculatePU;
          this.debtors[i].payable_until = calculatePU;
          let getTypeOfReference = await detector.detectTypeReference(currentDebtor.reference);
          let formattedReference = await this.formatReference(getTypeOfReference,currentDebtor.reference);
          this.debtors[i].reference = formattedReference;
              

          const ibansToValidate = [];

          if(getTypeOfReference === 'QRR'){
            if(this.userHasWir){
                 ibansToValidate.push(this.creditor.qrIBAN_wir);
                 ibansToValidate.push(this.creditor.qrIBAN);
            }else{
                 ibansToValidate.push(this.creditor.qrIBAN);
            } 
              
          }else{
              if(this.userHasWir){
                ibansToValidate.push(this.creditor.qr_account);
                ibansToValidate.push(this.creditor.qr_account_wir);
              }
              else{
                ibansToValidate.push(this.creditor.qr_account);
              }
          }
        
          try {
            debitorFullName(currentDebtor);
            address(currentDebtor);
            zip(currentDebtor);
            nameMissingZip(currentDebtor);
            debitorCountryRules(currentDebtor);
            city(currentDebtor);
            fieldRequired(currentDebtor);
            productsRequired(currentDebtor);
            invoiceDateRequired(currentDebtor);
            languages(currentDebtor)

          if((!!getTypeOfReference && this.checkArrayNotNulls(ibansToValidate))){
             this.debtorsFail.push(currentDebtor);
          }
           
          //validate references
          if (this.referenceLength(currentDebtor.reference) !=true || this.validationReference(currentDebtor.reference) != true ){
              this.debtorsFail.push(currentDebtor);                 
          }

          } catch (error) {
            this.debtorsFail.push(currentDebtor);
          }
        }
        if (this.debtorsFail.length === 0) {
          this.e1 = 4;
          this.$refs.finalStep.initFinal();
        } else {
          this.e1 = 3;
          this.$refs.qrMassiveStep.init();
          this.$refs.qrMassiveStep.runValidations();
        }
      });
    },
    cancelStep2() {
      this.$root
        .$confirm(
          this.$t("snack_confirm.cancel_title"),
          this.$t("snack_confirm.cancel_process")
        )
        .then((response) => {
          if (response) {
            this.$refs.qrMassiveDebtor.$refs.debtorTable.setdebtorsToZero();
            Object.assign(this.$refs.qrMassiveDebtor, {
              debtorsChecks: {
                address: true,
                reference: true,
                amount: true,
                message: true,
                billing: false,
                addData: false
              },
            });
            // this.e1 = 1;
            this.$router.push({name: "qr-table" });
          }
        });
    },
    step3() {
        
      let StepThree = this.$refs.qrMassiveStep.runValidations();
      if (!StepThree) {
        return this.$store.commit("global/setSnackbar", {
          message: this.$t("snack_confirm.discover_error"),
        });
      } else {
        this.$nextTick(async() => {
          let DebtorFiltered = [];
          await this.$refs.qrMassiveStep.updateCurrentDebtorsFail(this.$refs.qrMassiveStep.currentIndex);
          for(let i = 0;i<this.debtorsFail.length;i++){
            let currentDebtor = this.debtorsFail[i];
            try {
              debitorFullName(currentDebtor);
              address(currentDebtor);
              zip(currentDebtor);
              nameMissingZip(currentDebtor);
              debitorCountryRules(currentDebtor);
              city(currentDebtor);
              fieldRequired(currentDebtor);
              languages(currentDebtor);
              productsRequired(currentDebtor);
              if(this.templateType === 'complete'){
                invoiceDateRequired(currentDebtor);
              }
              if(this.debtorsFail.length >= 1){
                await this.$refs.qrMassiveStep.updateServerData(currentDebtor,(approved,message)=>{
                  if(!approved) DebtorFiltered.push(currentDebtor);
                })
              }
               
            } catch (error) {
              DebtorFiltered.push(currentDebtor);
            }
          }
          if (DebtorFiltered.length === 0) {
            this.e1 = 4;
            this.$refs.finalStep.initFinal();
          } else {
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("snack_confirm.discover_error"),
            });
          }
        });
      }
    },
    cancelStep3() {
      this.$root
        .$confirm(
          this.$t("snack_confirm.cancel_title"),
          this.$t("snack_confirm.cancel_process")
        )
        .then((response) => {
          if (response) {
            //to check if it is deleted
            this.$refs.qrMassiveStep.$refs.form.reset();
            // this.e1 = 1;
            this.$router.push({name: "qr-table" });
          }
        });
    },
    setCheckboxAddress(value) {
      this.checkboxAddress = value;
    },
    setCheckboxReference(value) {
      this.checkboxReference = value;
    },
    setCheckboxAmount(value) {
      this.checkboxAmount = value;
    },
    setCheckboxMessage(value) {
      this.checkboxMessage = value;
    },
    setCheckboxBilling(value) {
      this.checkboxBilling = value;
    },
    setCheckboxAddData(value) {
      this.checkboxAddData = value;
    },
    setProfile(value) {
      this.profile = value 
    },
    getInvoiceDates() {
      //Returns an object containing the values of the invoice dates
      //if the values are null the invoices won't be included
      const expeditionDate = this.$store.state.AI.structuredMessage.VAT_Date.start_date;
      const expirationDate = this.$store.state.AI.structuredMessage.VAT_Date.end_date;
      const dateObject = new Object();
      if (expeditionDate !== null && expeditionDate !== undefined) {
          dateObject["expeditionDate"] = expeditionDate;
      }
      if (expirationDate !== null && expirationDate !== undefined) {
          dateObject["expirationDate"] = expirationDate;
      }
      return dateObject;
    },
    formatReference(typeRefernce,reference){
      if(reference && typeRefernce){
        let newReference = reference.replace(/\s/g,'');
        if(typeRefernce === 'QRR'){
          if(newReference.length === 27){
            let responseReference = `${newReference.substring(0,2)}`;
            responseReference += ` ${newReference.substring(2,7)}`;
            responseReference += ` ${newReference.substring(7,12)}`;
            responseReference += ` ${newReference.substring(12,17)}`;
            responseReference += ` ${newReference.substring(17,22)}`;
            responseReference += ` ${newReference.substring(22,27)}`;
            return responseReference;
          }
        }else if(typeRefernce === 'SCOR'){
          if(newReference.length === 25){
            let responseReference = `${newReference.substring(0,4)}`;
            responseReference += ` ${newReference.substring(4,8)}`;
            responseReference += ` ${newReference.substring(8,12)}`;
            responseReference += ` ${newReference.substring(12,16)}`;
            responseReference += ` ${newReference.substring(16,20)}`;
            responseReference += ` ${newReference.substring(20,24)}`;
            responseReference += ` ${newReference.substring(24,25)}`;
            return responseReference;
          }
        }
      }
      return '';
    },

    //validate qr references
    referenceLength(reference) {
        const typeReference = detector.detectTypeReference(reference.trim()) 
        let formatedReference = reference.replace(/ /g, "");
        if (typeReference === "QRR") {
          if (formatedReference.length !== 27) {
            return this.$t("qrGeneratorFree.rules.referenceLength.QRR");
          } else {
            return true;
          }
        } else if (typeReference === "SCOR") {
          if (!(formatedReference.length >= 5 && formatedReference.length <= 25)) {
            return this.$t("qrGeneratorFree.rules.referenceLength.SCOR");
          } else {
            return true;
          }
        } else {
          return true;
        }
   },
    validationReference(reference){
          const typeReference = detector.detectTypeReference(reference.trim());
          let formatedReference = reference.replace(/ /g, "");
          if (typeReference === "QRR") {
            // Get the digit that should be the last digit in the reference
            let lastDigit = QRR(formatedReference);
            if (formatedReference.charAt(27) != lastDigit) {
              return this.$t("qrGeneratorFree.rules.reference.QRR");
            } else {
              return true;
            }
          } else if (typeReference === "SCOR") {
            if (formatedReference.length >= 5 && formatedReference.length <= 25) {
              try {
                let validation = ISO11649.validate(formatedReference);
                return validation || this.$t("qrGeneratorFree.rules.invalid_reference");
              } catch(e) {
                return this.$t("qrGeneratorFree.rules.reference.SCOR.invalid")
              }
            } else {
              return this.$t("qrGeneratorFree.rules.reference.SCOR.invalidLength");
            }
          }
          return true;
    }
  },
};
</script>