<template>
  <div>
    <template>
      <v-stepper v-model="e6" vertical>
        <v-stepper-step :editable="e6 >= 1 && allowFirstStepBack && e6 != 5" :complete="e6 > 1" step="1">
         {{$t('StepByStep.ChooseProduct.main_title')}}
        </v-stepper-step>
        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
                <subscription v-bind:propsMap="{memberships:items}" :URLSubscription="URLSubscription" :key="updateSubs" ref="subscription"></subscription>
            </v-col>
          </v-row>
          <v-btn color="primary" @click="membershipStep"> {{$t('StepByStep.ChooseProduct.continue_button')}} </v-btn>
        </v-stepper-content>
        <v-stepper-step :editable="e6 >= 2 && allowFirstStepBack && e6 != 5" :complete="e6 > 2" step="2">
          {{$t('StepByStep.UserInformation.main_title')}}
        </v-stepper-step>

        <v-stepper-content step="2">
          <signup-form ref="signupForm" :hidden_fields="['reference','paymentSelected']"></signup-form>
          <user ref="user" @userInputIbanWir="showFieldTypePayment" :wirPartners="wirPartners"  ></user>
          <v-form ref="terms">
            <v-checkbox
              v-model="terms_and_conditions"
              :rules="[rules.terms_and_con]"
            >
              <template v-slot:label>
                <div>
                {{$t('StepByStep.UserInformation.checkbox_conditions1')}}
                  <v-tooltip class="pt-0 pb-0" bottom>
                    <template v-slot:activator="{ on }">
                      <a target="_blank" :href="link_terms_and_condition" @click.stop v-on="on">
                      {{$t('StepByStep.UserInformation.checkbox_conditions')}}
                      </a>
                    </template>
                  </v-tooltip>
                </div>
              </template>
            </v-checkbox>
          </v-form>
          <v-btn color="primary" 
            :loading="DOMElements.continue.loading"
           @click="userStep"> {{$t('StepByStep.UserInformation.continue_button')}} </v-btn>
          <v-btn text @click="cancelStep2">{{$t('StepByStep.UserInformation.cancel_button')}}</v-btn>
        </v-stepper-content>

        <v-stepper-step :editable="e6 >= 3 && allowFirstStepBack && e6 != 5" :complete="e6 > 3" step="3">
       {{$t('StepByStep.PaymentMethod.main_title')}}
        </v-stepper-step>
        <v-stepper-content step="3">
          <v-row v-if="!cardError && !cardConfirmation">
            <v-col class="pt-0 pb-0" cols="1">
              <v-img 
              :src="logos.visa" 
              max-height="150"
              max-width="250"
              />
            </v-col>
            <v-col class="pt-3 pb-0" cols="1">
              <v-img 
              :src="logos.master_card" 
              max-height="150"
              max-width="250"
              />
            </v-col>
            <v-col class="pt-7 pb-0" cols="1">
              <v-img 
              :src="logos.post_finance" 
              max-height="150"
              max-width="250"
              />
            </v-col>
            <v-col class="pt-3 pb-0" cols="1">
              <v-img 
              v-if="$i18n.locale == 'en'"
              :src="logos.qr.en" 
              max-height="150"
              max-width="250"
              />
              <v-img 
              v-else-if="$i18n.locale == 'fr'"
              :src="logos.qr.fr" 
              max-height="150"
              max-width="250"
              />
              <v-img 
              v-else-if="$i18n.locale == 'it'"
              :src="logos.qr.it" 
              max-height="150"
              max-width="250"
              />
              <v-img 
              v-else
              :src="logos.qr.de" 
              max-height="150"
              max-width="250"
              />
            </v-col>
          </v-row>
          <v-row v-if="cardError">
            <v-col>
              <v-btn
              @click="changePaymentMethod()"
              color="primary"
              >{{$t("StepByStep.PaymentMethod.btn_other_card")}}</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pl-3">
            <div v-if="cardError">
              <h3>{{$t("StepByStep.error")}}</h3>
              <p> {{$t('StepByStep.PaymentMethod.error_message')}} <br>{{$t('StepByStep.PaymentMethod.error_code')}} {{errorCodeCard}}</p>
            </div>
            <div v-if="!cardError">
              <v-card class="pl-3" flat v-if="!cardConfirmation">
                <v-form ref="orderForm">
                  
                  <v-row v-if="showFieldPayment" >
                    <v-col cols="4" >
                      <v-select
                      :items="$t('StepByStep.OrderConfirmation.monthlyFees')"
                      v-model="monthlyFeesType"
                      :rules="[v => !!v || this.$t('rules.notempty')]"
                      ></v-select>
                    </v-col>
                  </v-row>
                  
                  <v-row>
                     <v-col>
                      <v-select
                      :no-data-text="$t('qrGenerator.productsTable.data_available')"
                      :items="$t('StepByStep.PaymentMethod.payment_entry')"
                      :disabled="edit"
                      :label="$t('StepByStep.OrderConfirmation.payment_method_title')"
                      v-model="user.paymentSelected"
                      :rules="[(v) => !!v || this.$t('rules.payment_rule')]"          
                      ></v-select>
                    </v-col>
                    
                    <v-col cols="4" v-if="user.paymentSelected == 'Card Method'">
                      <v-select
                      :disabled="edit"
                      :items="['Mastercard - VISA','PostFinance']"
                      v-model="cardPaymentType"
                      :rules="[v => !!v || this.$t('rules.notempty')]"
                      ></v-select>
                    </v-col>

                  </v-row>
                </v-form>
                <v-btn
                color="primary"
                :loading="loadingStateCheckoutButton"
                @click="goCheckout()"
                >{{$t('StepByStep.PaymentMethod.continue_button')}}</v-btn>
              </v-card>
              <v-card class="pl-5" flat v-if="cardConfirmation">
                <v-row>
                  <v-col>
                    <iframe style="border-style: none" :src="cardURL" height="500px" width="500px"></iframe>
                  </v-col>
                </v-row>
              </v-card>
            </div>
            </v-col>
          </v-row>
        </v-stepper-content>
        <v-stepper-step :editable="cardToken !== '' && e6 != 5" :complete="e6 > 4"  step="4">
          {{$t('StepByStep.OrderConfirmation.main_title')}}
        </v-stepper-step>
        <v-stepper-content step="4">
          <v-card v-if="step4.messageSent === false">
             <v-progress-linear indeterminate :active="processing" color="green"></v-progress-linear>
          
            <v-card-title v-if="errorFlag === true">{{orderConfirmationTitle}}</v-card-title>
          <v-card-title v-if="errorFlag === false">{{$t('StepByStep.OrderConfirmation.confirmation_text')}}</v-card-title>
            
            
            <v-card-subtitle v-if="errorFlag === true">{{orderConfirmationSubTitle}}</v-card-subtitle>
            <v-card-subtitle v-if="errorFlag === false" >{{$t('StepByStep.OrderConfirmation.confirmation_text1')}}</v-card-subtitle>

            <v-card-text v-if="!errorFlag">
              <v-row>
                <v-col cols="4">
                  <h3>{{$t('StepByStep.OrderConfirmation.confirmation_title')}}</h3>
                  <label>{{$t('StepByStep.OrderConfirmation.name_title')}}</label>
                  <p style="color:black">{{contractorName}}</p>
                </v-col>
                <v-col cols="4">
                  <h3>{{$t('StepByStep.OrderConfirmation.product_title')}}</h3>
                  <p style="color:black">{{orderConfirmationProduct}}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label>{{$t('StepByStep.OrderConfirmation.address_title')}}</label>
                  <p style="color:black">{{contractorAddress}}<br>{{contractorAddress2}}</p>  
                </v-col>
                <v-col cols="4">
                  <h3>{{$t('StepByStep.OrderConfirmation.fee_title')}}</h3>
                  <p style="color:black">CHF {{membershipPrice}}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4"></v-col>
                <v-col cols="4">
                  <h3>{{$t('StepByStep.OrderConfirmation.payment_method_title')}}</h3>
                  <p style="color:black">{{calculatePaymentMethod}}</p>
                </v-col>
              </v-row>
                  <v-btn
                  dark
                  color="primary"
                  :loading="loadingStateConfirmButton"
                  @click="confirmOrder"
                  >{{$t('StepByStep.OrderConfirmation.confirm_button')}}</v-btn>
                  <v-btn text
                  @click="cancelOrder"
                  >{{$t('StepByStep.OrderConfirmation.cancel_button')}}</v-btn>
            </v-card-text>
          </v-card>

          <v-card flat v-if="step4.messageSent === true">
            <p>{{$t('StepByStep.OrderConfirmation.activation_text')}}</p>
            <p>{{$t('StepByStep.OrderConfirmation.activation_text1')}}</p>
            <p>{{$t('StepByStep.OrderConfirmation.activation_text2')}}</p>
            <!-- <p>we have sent you an email with an activation link. Complete the registration by clicking on the link.</p> -->
            <!-- <p>When your profile is activated, the first monthly fee is booked</p> -->
          </v-card>
        </v-stepper-content>
        <v-stepper-step :complete="e6 > 5" step="5">
          {{$t('StepByStep.EmailConfirmation.main_title')}}
        </v-stepper-step>
        <v-stepper-content step="5">
          <v-row class="" >
            <v-col cols="12">
              <v-row v-if="step5.process_status === true" class="text-center" >
                  <v-col cols="12" >
                    <p>{{$t('StepByStep.OrderConfirmation.completed_text')}}</p>
                  </v-col>
                  <v-col cols="12" >
                    <v-btn href='https://qrmodul.ch/login'>{{$t('StepByStep.OrderConfirmation.gotologin_button')}}</v-btn>
                  </v-col>
                <v-row>
                </v-row>
                <v-row>
                </v-row>
              </v-row>

              <v-row v-if="step5.process_status === false && errorCode === 'R2' && !linkSended" >
                <v-col>
                 <!-- <p class="text-center" >{{$t('StepByStep.EmailConfirmation.message_error')}}<br>Error: {{errorCode}}</p> -->
                  <p>{{$t('StepByStep.OrderConfirmation.already_activated')}} </p>
                  <v-col cols="12" >
                  <v-btn href='https://qrmodul.ch/login' class="text-center">{{$t('StepByStep.OrderConfirmation.login_User')}}</v-btn>
                  </v-col>
                </v-col>
              </v-row>

              <v-row v-if="step5.process_status === false && errorCode === 'R16' && !linkSended">
                <v-col>
                 <!-- <p class="text-center" >{{$t('StepByStep.EmailConfirmation.message_error')}}<br>Error: {{errorCode}}</p> -->
                  <p>{{$t('StepByStep.OrderConfirmation.send_reactivation_email')}}</p>
                  <v-col cols="12" >
                  <v-btn class="text-center" @click="sendReactivationStep5()">{{$t('StepByStep.OrderConfirmation.btn_activation_link')}}</v-btn>
                  </v-col>
                </v-col>
              </v-row>

              <v-row v-if="linkSended" >
                <v-col>
                 <!-- <p class="text-center" >{{$t('StepByStep.EmailConfirmation.message_error')}}<br>Error: {{errorCode}}</p> -->
                  <p>{{$t('StepByStep.OrderConfirmation.activation_text1')}}</p>
                  <v-col cols="12" >
                  </v-col>
                </v-col>
              </v-row>

              <v-row v-if="step5.process_status === false && (errorCode != 'R16' && errorCode != 'R2')" >
                    <v-col>
                      <p class="text-center" >{{$t('StepByStep.EmailConfirmation.message_error')}}<br>Error: {{errorCode}}</p>
                    </v-col>
                  </v-row>

            </v-col>
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </template>
    <!-- *******************************************POP UP********************************************** -->
        <v-dialog  width="500"  v-model="popup"  transition="dialog-top-transition"
        max-width="600">
        <v-card flat  width="500"  >
       <v-toolbar
              :color="$vuetify.theme.themes.dark.green.secondary"
              dark
            > <h3>{{$t("CreditorFiles.account")}} {{statusAccount}}</h3></v-toolbar>
        <!-- <v-card-title   class="headline grey lighten-2"><h2> Account {{statusAccount}}</h2></v-card-title> -->
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
            <v-card-text  class="text-h5 pa-12"> {{messageStatus}} </v-card-text>
           <v-col cols="6" offset=3 >   <v-card-text >  <a class="justify-center text-h5 " v-if="mails" href="mailto:">info@qrmodul.ch </a></v-card-text></v-col>
     <!-- <v-card-actions class="justify"> -->
     <!-- </v-card-actions> -->
           <v-card-actions class="justify-end">
           <v-btn  v-if="btnActivation"
            @click="sendReactivation()"
            :disabled="disabledbtn"
            :dark="activateNewProfileDark"
            :color="$vuetify.theme.themes.dark.green.secondary"
            :loading="DOMElements.activate.loading"
            
           > {{$t("snack_confirm.btn_odernew")}}</v-btn> 
          <v-btn :color="$vuetify.theme.themes.dark.brown.secondary" :dark="activateNewProfileDark" @click="popup = false" > {{$t("snack_confirm.btn_close")}}</v-btn> 
           </v-card-actions>
    <v-spacer></v-spacer>
        </v-card>
    </v-dialog>
    <!-- ************************************************************************************************ -->

    <!--******************************************** Notification User Discount Partner **************************************************** -->
     <v-dialog
      transition="dialog-top-transition"
      v-model="showMasterDialog"
      width="500"
    >
        <v-card>
          <v-card-title class="text-h5 green-dark" >
            {{$t('Admin.Partners.partners')}}   
          </v-card-title>
          
          <v-card-text class="text-body-1 py-2">
             <!-- show message partner discount -->
             <span v-if="partnerName">
               {{ $t('Admin.Partners.messageDiscount').replace("{partnerName}",this.partnerName) }}
             </span>
             
          </v-card-text>
           <v-card-actions>
              <v-spacer></v-spacer>
               <v-btn color="primary" @click="showMasterDialog = false" > Close </v-btn>               
          </v-card-actions>
        </v-card>
    </v-dialog>
      <!-- *********************************************************************************************************************************** -->


    
  </div>
</template>

<script>
import products from "@/components/cockpit/ProductTable/products";
import NewSettings from "@/components/cockpit/My Profile/Settings/NewSettings.vue";
import signupForm from '@/components/signup/signup.form';
import qrFrom from '@/components/QR/qr.form';
import mixin_rules from '@/mixins/rules';

import { mapGetters } from "vuex";
import subscriptionVue from './subscription.vue';
import userVue from './user.vue';
export default {
  components: {
    products: products,
    settings: NewSettings,
    'signup-form': signupForm,
    'qr-form': qrFrom,
    'subscription': subscriptionVue,
    'user': userVue
  },
  data() {
    return {
      logos:{
        visa: require('./../../../public/MainVisaBlack.png'),
        master_card: require('./../../../public/mc_symbol_opt_73_3x.png'),
        post_finance: require('./../../../public/postfinancelogo.png'),
        qr:{
          de: require('./../../../public/qr_icon_de.png'),
          en: require('./../../../public/qr_icon_en.png'),
          it: require('./../../../public/qr_icon_it.png'),
          fr: require('./../../../public/qr_icon_fr.png')
        }
      },
      cardPaymentType:'Mastercard - VISA',
      monthlyFeesType:'Entire payment in CHF',
      partner:{type:null},
      wirPartners:[],
      showFieldPayment:false,
      UserSuscription: '',
      errorCodeCard:'',
      errorCode:'',
      loadingStateConfirmButton:false,
      loadingStateCheckoutButton:false,
      activateNewProfileDark:true,
      disabledbtn:false,
      mails:false,
      statusAccount: "",
      btnActivation: false,
      statuspopup: "",
      messageStatus: "",
      partnerName:"",
      showMasterDialog:false,
      popup: false,
      cardToken: '',
      cardConfirmation:false,
      cardURL:'',
      cardError:false,
      allowFirstStepBack:false,
      cardToken:'',
      currentSubs:``,
      contractorName:``,
      contractorAddress:``,
      contractorAddress2:``,
      membershipPrice:'',
      processing:false,
      errorFlag:false,
      updateSubs:0,
      edit: false,
      e6: 1,
      paymentItems:[
        'Card Method',
        'QR Bill',
      ],
      steps: {
        step1: {
          disabled: false,
        }
      },
      step4: {
        messageSent: false,
      },
      step5: {
        process_status: '',
      },
      token: '',
      amountCredits: 0,
      subcription: '',
      checkout_timer: 10,

      items: [],

      DOMElements: {
        activate: {
          loading: false,
        },
        continue:{
          loading:false,
        }
      },
      URLSubscription: '',
      linkSended:false
    };
  },
  destroyed(){
    window.removeEventListener('message',this.listenParentMessage,false);
  },
  computed: {
    rules() {
      return {
        terms_and_con: (value) =>
          !!value || this.$t('rules.terms_conditions'),
      }
    },
    orderConfirmationProduct(){
      if(this.$store.state.User.user.checkout){
        return this.$store.state.User.user.checkout
      }else{
        return '';
      }
    },
    orderConfirmationTitle(){
      if(this.errorFlag === true){
        return this.$t("StepByStep.error");
      }else{
        return this.$t('StepByStep.OrderConfirmation.confirmation_text');
      }
    },
    orderConfirmationSubTitle(){
      if(this.errorFlag === true){
        return this.$t("StepByStep.error_message");
      }else{
        return this.$t('StepByStep.OrderConfirmation.confirmation_text1');;
      }      
    },
    terms_and_conditions: {
      get() {
        return this.$store.state.steps.step1.terms_and_conditions;
      },
      set(value) {
        this.$store.commit("steps/setTermsAndConditions", value);
      },
    },
    passwordConfirmationRule() {
      return () => (this.change_password.new_password === this.change_password.new_password2) || this.$t("rules.passwordConfirmationRule")
    },
    link_terms_and_condition() {
      return `https://qrmodul.ch/${this.$i18n.locale}/agb/` 
    },
    ...mapGetters({
      user: 'User/user',
      creditor: 'User/creditor',
      profile: 'Profile/getProfile',
    }),
    calculatePaymentMethod(){
      if(this.user.paymentSelected) return this.$t(`StepByStep.PaymentMethod.payment_string.${this.user.paymentSelected}`);
      return '';
    }
  },
  created() {
    this.URLSubscription = this.$route.query.subscription;
  },
  async mounted() {
    this.mails = false;
    this.disableds = false;
    window.addEventListener('message',this.listenParentMessage,false);
    let urlParams = await new URLSearchParams(window.location.search);
    let getResponse = urlParams.get('response');
    if(getResponse){
      let responseObj = JSON.parse(getResponse);
      if(responseObj){
        if(responseObj.lang){
          let matchedLanguage = this.$parent.$parent.$parent.languagesArray.find(element => element.value ===responseObj.lang);
          if(matchedLanguage){
            this.$parent.$parent.$parent.languageSelector = responseObj.lang;
            this.$i18n.locale = responseObj.lang;
            this.$store.commit(`User/setLanguage`,responseObj.lang);
          }else{
            this.$parent.$parent.$parent.languageSelector = 'en';
            this.$i18n.locale = 'en';
            this.$store.commit(`User/setLanguage`,'en');
          }
        }
        let step = responseObj.step;
        let error = responseObj.error;
        if(step == 5) {
          if(error !== '') {
            this.step5.process_status = false;
            this.errorCode = error;
          }

          if(!error || error === '') {
            this.step5.process_status = true;
          }
          
          this.e6 = 5;

        }
      }

    }
    await fetch(`${process.env.VUE_APP_SERVER_URL}/api/memberships`,{
      method:'GET',
    })
    .then((result)=>result.json())
    .then(async(response)=>{
      if(response){
        if(response.status == 200){
          this.items = [...response.memberships];
          if(this.$store.state.User.user.checkout){
            let foundMem = await this.items.find(element => element.name ===  this.UserSuscription);
            if(foundMem){
              if(foundMem.name === 'Single Tariff'){
                this.membershipPrice = `0`
              }else{
                this.membershipPrice = `${foundMem.price / 100}.00`;
              }
            }            
          }
          return this.updateSubs += 1;
        }
        return this.$store.commit(`global/setSnackbar`,{message:response.message})
      }else{
        return this.$store.commit(`global/setSnackbar`,{message: this.$t("StepByStep.invalid_response")})
      }
    })
    .catch((err)=>{
      return this.$store.commit(`global/setSnackbar`,{message:err});
    })


    //get the partners that has the type wir 
    try{
      const response = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partners/wir`);
      const {data} = await response.json();
      this.wirPartners = data.partners;
    }catch(err){
       console.log(err);
    }

  },
  methods: {
    passwordError() {
      return this.change_password.new_password === this.change_password.new_password2 ? '' : this.$t("rules.passwordConfirmationRule")
    },
    showFieldTypePayment(value){
        

        //if the value is false is because the user input a iban that is not wir
        //so the user can change the type of payment methodss
        if(!value){
           this.monthlyFeesType = "Entire payment in CHF";
        }
            
        this.showFieldPayment = value; 
    },
    async goCheckout() {
      this.loadingStateCheckoutButton = true;
      if(!this.$refs.orderForm.validate()){
        this.loadingStateCheckoutButton = false;
        return this.$store.commit(`global/setSnackbar`,{message: this.$t("snack_confirm.invalid_inputs")});
      }
      if(this.user.paymentSelected == "QR Bill"){
        this.setConfirmValues();
        this.cardToken = ``;
        this.loadingStateCheckoutButton = false;
        return this.e6 = 4;
      }else if(this.user.paymentSelected == 'Card Method' || this.user.paymentSelected == 'postfinance'){
        if(this.cardToken !== '' && this.cardToken !== null && this.cardToken !== undefined){
          if(this.cardURL !== '' && this.cardURL !== null && this.cardURL !== undefined){
            return this.cardConfirmation = true; 
          }
        }
        let skipFlag = false;
        if(!!this.cardToken && this.cardToken !== ''){
          await this.$root.$confirm(this.$t("StepByStep.confirm_title"),this.$t("StepByStep.replace_credit")).then(async(confirmResponse)=>{
            if(confirmResponse){
              const params = encodeURIComponent(this.cardToken);
              try{
                
                let result = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/payment/card/registration/cancel?token=${params}`,{
                  headers:{
                    "Content-Type":"application/json"
                  }
                });
                let response = await result.json();
                if(response){
                  if(response.status != 200){
                    skipFlag = true;
                    this.$store.commit(`global/setSnackbar`,{message:response.message});  
                  }
                }else{
                  skipFlag = true;
                  this.$store.commit(`global/setSnackbar`,{message:this.$t("StepByStep.invalid_response")});
                }

              }catch(err){
                skipFlag = true;
                this.$store.commit(`global/setSnackbar`,{message:err});
              }
            }else{
              skipFlag = true;
            }
          })
        }
        if(skipFlag){
          return this.loadingStateCheckoutButton = false;
        }else{
          let postFinanceFlag = this.cardPaymentType === 'PostFinance' ? true : false;
          await fetch(`${process.env.VUE_APP_SERVER_URL}/api/payment/card/registration?lang=${this.$i18n.locale}&post=${postFinanceFlag}`)
          .then((result)=>result.json())
          .then((response)=>{
            if(response){
              if(response.status == 200){
                this.cardURL = response.redirectUrl;
                this.cardToken = response.token
                this.cardConfirmation = true;            
                this.loadingStateCheckoutButton = false;
              }else{
                return this.$store.commit(`global/setSnackbar`,{message:response.message})
              }
            }else{
              this.loadingStateCheckoutButton = false;
              return this.$store.commit(`global/setSnackbar`,{message:this.$t("StepByStep.invalid_response")});
            }
          })
          .catch((err)=>{
            this.loadingStateCheckoutButton = false;
            return this.$store.commit(`global/setSnackbar`,{message:err});
          })
        }
      }
    },

    /**
     * Step´ validations
     */
    async userStep() {
      this.DOMElements.continue.loading = true;
      // TODO: validate forms
      const signupForm = this.$refs.signupForm.$refs.form.validate();
      const user = this.$refs.user.$refs.form.validate();

      if(!signupForm || !user) {
        this.DOMElements.continue.loading = false;
        return this.$store.commit('global/setSnackbar', { message: this.$t("snack_confirm.discover_error") });
      }

      if(!this.terms_and_conditions) {
        this.DOMElements.continue.loading = false;
        return this.$store.commit('global/setSnackbar', { message: this.$t("rules.terms_conditions")  });
      }

      fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/validation`,{
        method:'POST',
        body:JSON.stringify({
          email:this.user.email,
          password:this.user.password,
          addressSuffixText:this.user.addressSuffixText,
          street:this.user.street,
          number:this.user.number,
          zip:this.user.zip,
          city:this.user.city,
          country:this.user.country,
          legalFormSelected:this.user.legalFormSelected,
          legalFormOthersText:this.user.legalFormOthersText,
          name:this.user.name,
          lastname:this.user.lastname,
          industrySelected:this.user.industrySelected,
          industryOthersText:this.user.industryOthersText,
          numberMembersSelected:this.user.numberMembersSelected,
          numberEmployeesSelected:this.user.numberEmployeesSelected,
          UIDText:this.user.UIDText,
          referenceText:this.user.referenceText,
          salutationText:this.user.salutationText,
          companyText:this.user.companyText,
          paymentSelected:this.user.paymentSelected,
          checkout:this.user.checkout,
          account: this.creditor.account,
          qr_account: this.creditor.qr_account,
        })
      })
      .then((result)=>result.json())
      .then((response)=>{
          this.DOMElements.continue.loading = false;
        if(response){
          if(response.status == 200){
            this.e6 = 3;
          }else{
            if(response.popupStatus == "inactive/pending"){
              this.popup = true;
              this.mails= false;
              this.statusAccount = response.popupStatus;
              this.btnActivation= true;
              this.messageStatus = this.$t("snack_confirm.activate_profile")
            }
            if(response.popupStatus == "active"){
              this.btnActivation= false;
              this.popup = true ;
              this.mails = false;
              this.statusAccount = response.popupStatus;
              this.messageStatus = this.$t("snack_confirm.login_profile")
            }
            else if(response.popupStatus == "locked"){
              this.btnActivation= false;
              this.popup = true ;
              this.statusAccount = response.popupStatus;
              this.mails = true;
              this.messageStatus = this.$t("snack_confirm.unlock_profile")
            }
            return this.$store.commit(`global/setSnackbar`,{message:response.message});
          }
        }else{
          return this.$store.commit(`global/setSnackbar`,{message:this.$t("StepByStep.invalid_response")});
        }
      })
      .catch((err)=>{
        return this.$store.commit(`global/setSnackbar`,{message:err})
      })

    },

    cancelStep2() {
      this.$root.$confirm(this.$t("snack_confirm.cancel_title"), this.$t("snack_confirm.cancel_process"))
      .then(response => {
        if(response) {
          return window.parent.postMessage({ url: `https://qrmodul.ch/${ this.$i18n.locale == "de"?"":this.$i18n.locale }`, registration:true }, "*");
        }
      })
    },

    membershipStep() {
      let subscription = this.$refs.subscription.subscription;
      this.$t('StepByStep.ChooseProduct.product_entry').forEach(element => {
      if(subscription == element.value){
        this.UserSuscription = element.value
        subscription = element.text
      }
      });

    
      if(subscription !== '' && subscription !== undefined) {
        this.$store.commit('User/setCheckout', subscription);
        this.allowFirstStepBack = true;
      }
      if (subscription === '' || subscription === undefined || subscription === null)
      {
        return this.$store.commit('global/setSnackbar', { message: this.$t("snack_confirm.message_product") })
      }
      this.e6 = 2;
    },
    cancelOrder(){
      this.$root.$confirm(this.$t("StepByStep.UserInformation.cancel_button"),this.$t("snack_confirm.cancel_registration"),{color:`red`})
      .then((response)=>{
        if(response){
          return window.parent.postMessage({ url: `https://qrmodul.ch/${ this.$i18n.locale == "de"?"":this.$i18n.locale }`, registration:true }, "*");
        }
      });
    },
    async confirmOrder(){
      if(!this.errorFlag){
        if(this.$store.state.User.user.checkout){
          this.loadingStateConfirmButton = true;
        // TODO: call the url for send email confirmation
        try {
          let dataMap = {
            email:this.user.email,
            password:this.user.password,
            addressSuffixText:this.user.addressSuffixText,
            street:this.user.street,
            number:this.user.number,
            zip:this.user.zip,
            city:this.user.city,
            country:this.user.country,
            legalFormSelected:this.user.legalFormSelected,
            legalFormOthersText:this.user.legalFormOthersText,
            name:this.user.name,
            lastname:this.user.lastname,
            industrySelected:this.user.industrySelected,
            industryOthersText:this.user.industryOthersText,
            numberMembersSelected:this.user.numberMembersSelected,
            numberEmployeesSelected:this.user.numberEmployeesSelected,
            UIDText:this.user.UIDText,
            referenceText:this.user.referenceText,
            salutationText:this.user.salutationText,
            companyText:this.user.companyText,
            paymentSelected:this.user.paymentSelected,
            monthlyFeesType:this.monthlyFeesType,
            checkout: this.UserSuscription,
            account: this.creditor.account,
            qr_account: this.creditor.qr_account,
            lang:this.$i18n.locale
          };

          //add the properties account_wir  qr_account_wir if the user input a iban with wir 
          if(this.creditor.accountWir){
              dataMap.accountWir = this.creditor.accountWir;
          }

          if(this.creditor.qr_account_wir){
              dataMap.qr_account_wir = this.creditor.qr_account_wir;
          }
          
          const reqEmailConf = await fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/confirm`, {
            method: 'POST',
            body: JSON.stringify({
              data: dataMap,
              token: this.cardToken,
            })
          });
          
          const { message, status , partner } = await reqEmailConf.json();
          if(message === 'Success' && status === 200) {
            this.loadingStateConfirmButton = false;
            this.step4.messageSent = true;
            this.allowFirstStepBack = false;

            
            if(partner && partner.model == "Discount"){
                //show partner message
                
                if(this.$refs.subscription.subscription != "Single Tariff"){
                    
                    this.showMasterDialog = true;
                    this.partnerName = partner.name;

                }
                
            }

          }
          if(status !== 200) {
            this.loadingStateConfirmButton = false;
            return this.$store.commit(`global/setSnackbar`,{ message: message });
          }
        } catch (error) {
           this.loadingStateConfirmButton = false;
          return this.$store.commit(`global/setSnackbar`,{message:error})
        }
        }else{
           this.loadingStateConfirmButton = false;
          return this.$store.commit(`global/setSnackbar`,{message:this.$t("StepByStep.requested_profile_not")});
        }
      }else{
         this.loadingStateConfirmButton = false;
        return this.$store.commit(`global/setSnackbar`,{message:this.$t("StepByStep.request_not_processed")});
      }
    },
    changePaymentMethod(){
      this.cardError = false;
      this.cardConfirmation = false;

    },
    listenParentMessage(event){
      if(event.origin !== `${process.env.VUE_APP_FRONT_URL}`){
        return
      }else{
        if(this.cardConfirmation){
          if(event.data){
            if(typeof event.data === 'object'){
              if(event.data.message){
                if(event.data.message !== 'Success'){
                  this.errorCodeCard = event.data.errorCode;
                  this.cardToken = ``;
                  this.cardURL = ``;
                  if(event.data.errorCode === 'cancelled'){
                    this.cardConfirmation = false;
                  }else{
                    this.cardError = true;
                  }
                  return
                }else{
                  this.cardURL = ``;
                  this.cardConfirmation = false;
                  this.setConfirmValues();
                  return this.e6 = 4;
                }
              }else{
                return
              }
            }else{
              return 
            }
          }
        }else{
          return  
        }
      }
    },
    setConfirmValues(){
      //------Sets the name for the confirm screen-------//
      if(this.user.name == ''){
        this.contractorName = `${this.user.lastname}`;
      }else{
        this.contractorName = `${this.user.name} ${this.user.lastname}`
      }
      //------Sets the address for the confirm screen-------//
      if(this.user.street == ''){
        this.contractorAddress = `${this.user.street}`;
      }else{
        this.contractorAddress = `${this.user.street} ${this.user.number}`;
      }
      //------Sets the address 2 for the confirm screen-------//
      if(this.user.zip == ''){
        this.contractorAddress2 = `${this.user.city}`;
      }else{
        this.contractorAddress2 = `${this.user.zip} ${this.user.city}`;
      }
      
      //------Sets the address 2 for the confirm screen-------//
      if(this.$store.state.User.user.checkout) {
        let foundMem = this.items.find(element => element.name === this.UserSuscription);
        if(foundMem) {
          if(foundMem.name === 'Single Tariff'){
            this.membershipPrice = `0`
          }else{
            this.membershipPrice = `${foundMem.price / 100}.00`;
          }
        }
      }
    },
    sendReactivation(){
      this.DOMElements.activate.loading = true;
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/email/sendReactivation?email=${this.user.email}`,{
        method:'GET',
      })
      .then((result)=>result.json())
      .then(async(response)=>{
        this.DOMElements.activate.loading = false;
        this.disabledbtn= true;
        this.activateNewProfileDark =false;
        return this.$store.commit(`global/setSnackbar`,{ message: response.message });
      })
      this.linkSended = true;
    },
    async sendReactivationStep5(){
      let urlParams = await new URLSearchParams(window.location.search);
      let getResponse = JSON.parse(urlParams.get('response'));
      
      this.DOMElements.activate.loading = true;
      fetch(`${process.env.VUE_APP_SERVER_URL}/api/email/sendReactivation?email=${getResponse.email}`,{
        method:'GET',
      })
      .then((result)=>result.json())
      .then(async(response)=>{
        this.DOMElements.activate.loading = false;
        this.disabledbtn= true;
        this.activateNewProfileDark =false;
        return this.$store.commit(`global/setSnackbar`,{ message: response.message });
      })
      this.linkSended = true;
    }
  },
  watch:{
     monthlyFeesType(newValue,oldValue){
        if(newValue == "50% of payment in CHW and 50% in CHF"){
            this.user.paymentSelected =  "QR Bill"
            this.edit = true;
        }else{
           this.edit  = false;
        }
     }
  },
  mixins: [mixin_rules]
};
</script>

<style scoped>

.main-controls {
  position: fixed;
  width:14em;
  right: 3em;
  bottom: 1em;
}

.green-dark{
   background-color:#95C11F;
   color:white;
}

</style>