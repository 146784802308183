<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col class="py-0">
              <v-card-title
                >{{ $t("ProductUsage.MyProducts.main_title") }}
              </v-card-title>
            </v-col>
            <v-col class="py-0">
              <v-card-title
                >{{ $t("ProductUsage.MyProducts.additional_services") }}
              </v-card-title>
            </v-col>
            <v-col class="py-0">
              <v-card-title
                >{{ $t("ProductUsage.MyProducts.cost_overview") }}
              </v-card-title>
            </v-col>
          </v-row>
          <v-form ref="form">
            <v-row>
              <v-col>
                <!-- Your product Field -->
                <v-select
                  :no-data-text="$t('qrGenerator.productsTable.data_available')"
                  :items="$t('StepByStep.ChooseProduct.product_entry')"
                  item-text="text"
                  item-value="value"
                  :label="$t('ProductUsage.MyProducts.your_product')"
                  v-model="QrInfo.membershipName"
                  validate-on-blur
                  :disabled="vselect"
                ></v-select>
              </v-col>
              <v-col>
                <!-- Additional QR Bills / Costs-->
                <v-text-field
                  :label="$t('ProductUsage.MyProducts.additional_qrbills')"
                  v-model="QrInfo.extraBillsAux"
                  validate-on-blur
                  disabled
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  :label="$t('ProductUsage.MyProducts.total_cost_month')"
                  v-model="QrInfo.totalPriceMonth"
                  validate-on-blur
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :label="$t('ProductUsage.MyProducts.price')"
                  v-model="QrInfo.membershipPrice"
                  validate-on-blur
                  disabled
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  disabled
                  :label="$t('ProductUsage.MyProducts.orders_to_print')"
                  v-model="QrInfo.totalOrders"
                ></v-text-field>
              </v-col>
              <v-col class="pl-0 pr-0 pb-0 pt-7">
                <v-btn
                  :color="$vuetify.theme.themes.dark.green.primary"
                  v-show="show"
                  dark
                  @click="next(QrInfo.membershipName)"
                  :loading="DOMElements.next.loading"
                  >{{ $t("ProductUsage.MyProducts.btn_save") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <!-- BOTON POSICIONAR DERECHA -->
                <v-btn
                  :color="$vuetify.theme.themes.light.blue.primary"
                  dark
                  @click="(vselect = false), (show = true)"
                  >{{ $t("ProductUsage.MyProducts.btn_select") }}</v-btn>
              </v-col>
              <v-col>
                <div v-if="ok" >
                  <v-text-field
                    :label="$t('ProductUsage.MyProducts.conversion_in_ebill')"
                    v-model="QrInfo.eBillAux"
                    validate-on-blur
                    disabled
                  ></v-text-field>
                </div>
              </v-col>
              <v-col class="pa-0">
                <v-btn
                  :color="$vuetify.theme.themes.dark.brown.primary"
                  v-show="show"
                  dark
                  @click="(vselect = true), (show = false), initialize()"
                  >{{ $t("ProductUsage.MyProducts.btn_cancel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <!-- *************** -->
        </v-card-text>
      </v-card>
    </v-container>
    <!-- SECOND CONTAINER -->
    <v-container>
      <v-row>
        <v-col cols="8" class="pr-40">
          <v-card>
            <v-card-text>
              <!--   Key figures july 2020   -->
              <v-card-title>
                <!-- <p id="KeyF">
                </p> -->
                {{ printMonth }}
              </v-card-title>
              <v-row>
                <div>
                  <v-card flat>
                    <v-card-title
                      >{{ $t("ProductUsage.KeyFigures.chose_period") }}
                    </v-card-title>
                    <!-- Esto son los botones de flecha  y el de la fecha -->
                    <v-col class="py-0">
                      <v-col cols="2" class="ml-0 mr-0 pl-1 pr-0 mt-0 pb-0">
                        <v-row>
                          <v-col>
                            <v-btn @click="datepickers.calendardialog=true">
                              <v-icon>mdi-calendar-month</v-icon>
                              </v-btn>
                            <v-dialog v-model="datepickers.calendardialog"
                            width="290px" >
                              <v-date-picker
                              v-model="picker"
                              type="month"
                              scrollable
                              :locale="$store.state.User.language"
                              :max="today2"
                              @change="changeCalendar()"
                              >
                               <v-spacer></v-spacer>
                          <v-btn
                            :color="$vuetify.theme.themes.light.blue.primary"
                            @click="datepickers.calendardialog=false"
                            dark
                          >{{$t("Globals.ok")}}</v-btn>
                          <!-- <v-btn
                            :color="$vuetify.theme.themes.light.blue.primary"
                            dark
                            @click="$refs.mwstStartDate.save($store.state.AI.structuredMessage.VAT_Date.start_date)"
                          >{{$t('qrGenerator.billingInformation.vatStartDate_field.ok_button')}}</v-btn> -->
                              </v-date-picker>
                            </v-dialog>
                          </v-col>
                          <v-row>
                            <v-col cols="4" class="pl-4 pr-0 mr-0">
                              <v-btn
                                fab
                                small
                                @click="goPrevious()"
                                class="mr-0 pl-0"
                                :color="
                                  $vuetify.theme.themes.dark.green.secondary
                                "
                              >
                                <v-icon large
                                  >mdi-arrow-left-circle</v-icon
                                >
                              </v-btn>
                            </v-col>
                            <v-col cols="4" class="pl-10 ml-0 pr-0 mr-0">
                              <v-btn
                                fab
                                small
                                class="mr-0 pl-0"
                                @click="goNext()"
                                :color="
                                  $vuetify.theme.themes.dark.green.secondary
                                "
                              >
                                <v-icon large
                                  >mdi-arrow-right-circle</v-icon
                                >
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-row>
                      </v-col>
                    </v-col>
                    <!-- Aqui acaban los botones de flecha -->
                  </v-card>
                </div>

                <v-col class="py-0">
                  <!-- Generate Inoives Field -->
                  <v-row>
                    <v-col class="py-0">
                      <h4>
                        {{ $t("ProductUsage.KeyFigures.generated_invoices") }}
                      </h4>
                      <p>{{ this.QrInfo.invoicesGenerated }}</p>
                    </v-col>
                  </v-row>
                  <!-- *********************** -->
                  <!-- incluiding_proper_bills field  V3-->
                  <v-row v-if="ok">
                    <v-col class="py-0">
                      <h4>
                        {{
                          $t("ProductUsage.KeyFigures.incluiding_proper_bills")
                        }}
                      </h4>
                      <p>{{ this.QrInfo.properBills }}</p>
                    </v-col>
                  </v-row>
                  <!-- ************** -->
                  <!-- davon1  V3 -->
                  <v-row v-if="ok">
                    <v-col class="py-0">
                      <h4>{{ $t("ProductUsage.KeyFigures.davon1") }}</h4>
                      <p>{{ this.QrInfo.firstReminder }}</p>
                    </v-col>
                  </v-row>
                  <!-- *************** -->
                  <!-- davon2  V3-->
                  <v-row v-if="ok">
                    <v-col class="py-0">
                      <h4>{{ $t("ProductUsage.KeyFigures.davon2") }}</h4>
                      <p>{{ this.QrInfo.secondReminder }}</p>
                    </v-col>
                  </v-row>
                  <!-- **************** -->
                  <!-- Remainin Balance Field -->
                  <v-row>
                    <v-col class="py-0">
                      <h4>
                        {{ $t("ProductUsage.KeyFigures.remaining_balance") }}
                      </h4>
                      <p>{{ this.QrInfo.remainingBalance }}</p>
                    </v-col>
                  </v-row>
                  <!-- ************** -->
                </v-col>
                <!-- </v-card> -->
                <!-- ESTA ES LA TERCERA COLUMNA -->
                <!-- <v-card> -->
                <v-col class="py-0">
                  <!-- Set invoices Field  V2-->
                  <v-row v-if="ok">
                    <v-col class="py-0">
                      <h4>{{ $t("ProductUsage.KeyFigures.sent_invoices") }}</h4>
                      <p>{{ this.QrInfo.sentInvoices }}</p>
                    </v-col>
                  </v-row>
                  <!-- ************** -->
                  <!-- Paid bills V3 -->
                  <v-row v-if="ok">
                    <v-col class="py-0">
                      <h4>{{ $t("ProductUsage.KeyFigures.paid_bills") }}</h4>
                      <p>{{ this.QrInfo.paidBills }}</p>
                    </v-col>
                  </v-row>
                </v-col>
                <!-- </v-card> -->
              </v-row>
              <v-row v-if="ok">
                <v-col cols="16" class="pl-10">
                  <v-card>
                    <div>
                      <v-row>
                        <v-col class="py-0">
                          <h4>
                            {{ $t("ProductUsage.KeyFigures.invoice_amount") }}
                          </h4>
                          <p>{{ this.QrInfo.totalAmount }}</p>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="py-0">
                          <h4>{{ $t("ProductUsage.KeyFigures.pay_by_it") }}</h4>
                          <p>{{ this.QrInfo.paidByIt }}</p>
                        </v-col>
                        <v-col class="py-0">
                          <h4>
                            {{ $t("ProductUsage.KeyFigures.thereof_open") }}
                          </h4>
                          <p>{{ this.QrInfo.toBePaid }}</p>
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-text>
              <!-- My Accounts -->
              <v-card-title
                >{{ $t("ProductUsage.MyAccounts.main_title") }}
              </v-card-title>
              <v-row  class="pl-6">
                <v-col class="pl-3">
                  <!-- <v-btn @click="datepickers.calendardialog2=true">
                    <v-icon>mdi-calendar-month</v-icon>
                  </v-btn> -->
                  <v-btn 
                    :color="$vuetify.theme.themes.light.blue.primary"
                    fab
                    x-large
                    dark 
                    @click="datepickers.calendardialog2=true"
                  >
                    <v-icon  >mdi-calendar-month</v-icon>
                  </v-btn>
                  <v-dialog persistent v-model="datepickers.calendardialog2"
                    width="290px" >
                    <v-date-picker
                      v-model="picker2"
                      type="month"
                      scrollable
                      :locale="$store.state.User.language"
                      :max="today2"
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                    :color="$vuetify.theme.themes.light.blue.primary"
                    @click="OpenInvoiceD"
                    dark
                    >{{$t("Globals.ok")}}</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <!-- <v-col cols="6" class="pt-0 pb-0">
                  <h4>{{$t('ProductUsage.MyAccounts.year')}}</h4>
                  <v-select
                   :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    :items="years"
                    item-text="type"
                    item-value="value"
                    class="pa-0 ma-0"
                    :label="$t('ProductUsage.MyAccounts.please_select')"
                    outlined
                    dense
                    v-model="yearsValue"
                    @change="setYears()"
                  ></v-select>
                </v-col> -->
              </v-row>
              <v-row>
                <!-- <v-col cols="6" class="pt-0 pb-0">
                  <h4>{{$t('ProductUsage.MyAccounts.month')}}</h4>
                  <v-select
                    :no-data-text="$t('qrGenerator.productsTable.data_available')"
                    :items="months"
                    item-text="name"
                    item-value="value"
                    v-model="monthsValue"
                    @change="setMonths()"
                    class="pa-0 ma-0"
                    :label="$t('ProductUsage.MyAccounts.please_select')"
                    outlined
                    dense
                  ></v-select>
                </v-col> -->
              </v-row>

              <v-row >
                <v-col  class="py-2">
                  <v-btn
                    v-if="btnShow"
                    :color="$vuetify.theme.themes.light.blue.primary"
                    dark
                    @click="showInvoices()"
                    class="container-statements-btn"
                    :loading="DOMElements.showInvoices.loading"
                    >{{ $t("ProductUsage.MyAccounts.btn_showinvoices") }}</v-btn>
                </v-col>
              </v-row>

              <v-row v-if="subscriptionName=='Angebot QR-Profi' || subscriptionName=='Angebot QR-Plus'" >
                 <v-col class="py-2">
                    <v-btn
                      v-if="btnShow"
                      :color="$vuetify.theme.themes.light.blue.primary"
                      dark
                      @click="showJournals()"
                      class="container-statements-btn"
                      :loading="DOMElements.showInvoices.loading"
                      >{{ $t("ProductUsage.MyAccounts.btn_showJournal") }}</v-btn>
                 </v-col>
              </v-row>

              <!-- V-dialog  para confirmar la desactivación de la cuenta-->
              <v-dialog
                v-model="deleteconfirmation"
                persistent
                max-width="500"
              >
                <v-card>
                  <v-card-title class="headline">{{$t("ProductUsage.delete_profile")}}</v-card-title>
                  <v-card-text>
                  {{$t("ProductUsage.MyAccounts.message_delete")}}
                  </v-card-text>
                <v-card-actions>
                  <v-btn
                      :color="$vuetify.theme.themes.dark.brown.primary"
                      dark
                      @click="deleteconfirmation2 = true"
                      >{{
                        $t("ProductUsage.MyProducts.btn_profileConfirm")
                      }}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="$vuetify.theme.themes.light.blue.primary"
                      small
                      text
                      @click="deleteconfirmation = false"
                    >
                    {{$t("ProductUsage.MyProducts.btn_cancel")}}
                    </v-btn>
                 </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Segunda confirmación --> 
              <v-dialog
                v-model="deleteconfirmation2"
                persistent
                max-width="500"
              >
                <v-card>
                  <v-card-title class="headline">{{$t("ProductUsage.delete_profile")}}</v-card-title>
                  <v-card-text>
                    {{$t("ProductUsage.MyAccounts.message_acknowledge")}}
                  </v-card-text>
                <v-card-actions>
                  <v-btn
                      :color="$vuetify.theme.themes.dark.brown.primary"
                      dark
                      @click="deactivate()"
                      :loading="DOMElements.deactivate.loading"
                      >{{$t("ProductUsage.MyProducts.btn_profileConfirm")}}</v-btn
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                      :color="$vuetify.theme.themes.light.blue.primary"
                      small
                      text
                      @click="deleteconfirmation2 = false, deleteconfirmation = false"
                    >
                      {{$t("ProductUsage.btn_cancel")}}
                    </v-btn>
                 </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Última confirmación, rederigir a qrmodul.ch --> 
              <v-dialog
                v-model="deleteconfirmation3"
                persistent
                max-width="500"
              >
                <v-card>
                  <v-card-title class="headline">{{$t("ProductUsage.delete_profile")}}</v-card-title>
                  <v-card-text>
                    {{$t("ProductUsage.profile_deleted")}}
                  </v-card-text>
                <v-card-actions>
                    <v-btn
                      :color="$vuetify.theme.themes.light.blue.primary"
                      small
                      href = "https://qrmodul.ch/"
                      dark
                    >
                     {{$t("Globals.ok")}}
                    </v-btn>
                 </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row>
                <v-col  class="py-2">
                  <v-btn
                    :color="$vuetify.theme.themes.dark.brown.secondary"
                    dark
                    @click="deleteconfirmation = true"
                    >{{
                      $t("ProductUsage.MyProducts.btn_profileDelete")
                    }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>  
          <!-- this dialog show a invoices -->
         <v-dialog  persistent  v-model="showTable">
            <v-card class="pt-8">
              <v-card-title>{{$t("Admin.GeneralUsageInfo.invoices")}}</v-card-title>
              <v-card-text> 
                <v-form>
                  <invoice-table :key="render" :YearInvoice="YearInvoice" :MonthInvoice="MonthInvoice" ref="InvoiceTable"/>
                </v-form>
             </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  dark
                  @click="CloseInvoiceD"
                  :color="$vuetify.theme.themes.light.blue.primary"
                >{{$t("QrSeries.step_four.PrintShop.cancel")}}</v-btn>
              <v-btn
                :color="$vuetify.theme.themes.light.blue.primary"
                 @click="showTable = false"
                dark
              > {{$t("QrSeries.step_four.PrintShop.confirm")}} </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-card>
      <v-footer v-if="true" dense padless>
        <v-card  dense flat  align="center"  justify="center" tile  width="100%">
          <v-row  no gutters justify="center">
          <v-card-title align="center" justify="center">
            {{this.$t("ProductUsage.contact_title")}}
          </v-card-title>
          </v-row>
          <v-card-text dark>
              <div v-if="SingleAndStart">
                <h3>{{this.$t("ProductUsage.single_tariff_qr_start")}} <a href='mailto:info@qrmodul.ch'>info@qrmodul.ch</a></h3>
              </div>
               <div v-if="PlusAndProfi">
                <h3> {{this.$t("ProductUsage.telephone")}}  <a href='tel:0442620202'> 044 262 02 02</a></h3>
              </div>
          </v-card-text>
        </v-card>
      </v-footer>
      </v-card>
    </v-container>

    <!-- this dialog show a journals of users -->

    <v-dialog persistent v-model="showTableJournal">
            <v-card class="pt-8">
              <v-card-title>{{$t("Admin.GeneralUsageInfo.journal")}}</v-card-title>
              <v-card-text> 
                <v-form>
                  <journal-table :key="render" :YearInvoice="YearInvoice" :MonthInvoice="MonthInvoice" />
                </v-form>
             </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  dark
                  @click="closeJournalDialog"
                  :color="$vuetify.theme.themes.light.blue.primary"
                >{{$t("QrSeries.step_four.PrintShop.cancel")}}</v-btn>
              <v-btn
                :color="$vuetify.theme.themes.light.blue.primary"
                 @click="showTableJournal = false"
                dark
              > {{$t("QrSeries.step_four.PrintShop.confirm")}} </v-btn>
              </v-card-actions>
            </v-card>
    </v-dialog>


  </div>
</template>


<script>
import invoicetable from  '@/components/cockpit/My Profile/ProductUsage/InvoiceTable';
import journaltable from  '@/components/cockpit/My Profile/ProductUsage/journalTable';
import months from "../../../../months.json";

export default {
  components:{
    "invoice-table":invoicetable,
    "journal-table":journaltable
  },
  beforeMount(){
      this.subscriptionName = this.$store.getters["User/subscriptionName"];
  },
  data: () => ({
    PlusAndProfi: false,
    SingleAndStart: true,
    btnShow: false,
    render:0,
    YearInvoice:"",
    MonthInvoice:"",
    menu1: false,
    menu2: false,
    showTable: false,
    showTableJournal:false,
    subscriptionName:"",
    picker2: new Date().toISOString().substr(0, 10),
    ok: false,
    picker: new Date().toISOString().substr(0, 10),
    datepickers:{
    calendardialog: false,
    calendardialog2: false
    },
    confirmation: false,
    deleteconfirmation: false,
    deleteconfirmation2: false,
    deleteconfirmation3: false,
    vselect: true,
    show: false,
    subscription: "",
    yearsValue: "",
    monthsValue: '',
    value2022: "",
    date: new Date(),
    dateAux: "",
    today2: new Date().toISOString().substr(0, 10),
    months,
    years: [],
    memberships: [],
    currentUser: 0,
    QrInfo: {
      membershipName: "",
      membershipPrice: "",
      extraBills: "",
      extraBillsPrice: "",
      extraBillsAux: "",
      printQuantity: "",
      printPrice: "",
      printAux: "",
      eBillQuantity: "",
      eBillPrice: "",
      eBillAux: "",
      totalPriceMonth: "",
      invoicesGenerated: "",
      properBills: "",
      firstReminder: "",
      secondReminder: "",
      remainingBalance: "",
      sentInvoices: "",
      paidBills: "",
      totalAmount: "",
      paidByIt: "",
      toBePaid: "",
      accountBills: [],
      totalOrders:"",  
    },
    bills: [],
    DOMElements: {
      next: {
        loading: false,
      },
      showInvoices: {
        loading: false,
      },
      deactivate: {
        loading: false,
      }
    }
  }),
  created() {

    //This commit  changes the title on the dashboard
     this.$store.commit("global/setPageTitle",this.$t('ProductUsage.main_title'));
    //We call the following method
    this.initialize();
  },
  watch:{
    locale(newValue,oldValue){
      this.$store.commit("global/setPageTitle",this.$t('ProductUsage.main_title'))
    },
    date(newValue, oldValue){
      // console.log(newValue)
    }
  },
  computed:{
    locale(){
      return this.$store.state.User.language;
    },
     printMonth() {
      const newDate = this.date;
      const Month1 = this.date.getMonth();
      const year = this.date.getFullYear();

      switch (Month1) {
        case 0:
          //Statements executed when the result of expression matches valor1
          return this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.january") + year + ".";
        case 1:
          //Statements executed when the result of expression matches valor2
          return  this.$t("ProductUsage.KeyFigures.main_title")+ this.$t("Months.february") + year + ".";
        case 2:
          //Statements executed when the result of expression matches valorN
          return  this.$t("ProductUsage.KeyFigures.main_title")+ this.$t("Months.march") + year + ".";
        case 3:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.april") + year + ".";
        case 4:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.may") + year + ".";
        case 5:
          return this.$t("ProductUsage.KeyFigures.main_title")+ this.$t("Months.june") + year + ".";
        case 6:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.july") + year + ".";
        case 7:
          return  this.$t("ProductUsage.KeyFigures.main_title")+ this.$t("Months.august") + year + ".";
        case 8:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.september") + year + ".";
        case 9:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.october") + year + ".";
        case 10:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.November") + year + ".";
        case 11:
          return  this.$t("ProductUsage.KeyFigures.main_title")+  this.$t("Months.December") + year + ".";
        default:
          //Statements executed when none of the values match the value of the expression
          break;
      }
    },
  },
  methods: {
    ShowFooter(){
      if(this.QrInfo.membershipName == "Angebot QR-Plus" || this.QrInfo.membershipName == "Angebot QR-Profi"){
        this.PlusAndProfi = true;
      }else{
        this.PlusAndProfi = false;
      }
    },
    async next(SelectedSubscription) {
      
      let newSubs = await this.$t('StepByStep.ChooseProduct.product_entry').filter(
        (element) => element.value  === SelectedSubscription
      );  


      if (
        SelectedSubscription === this.$store.getters["User/subscriptionName"]
      ) {
        this.DOMElements.next.loading = true;
        await fetch(`${process.env.VUE_APP_SERVER_URL}/api/expiredMemebership?lang=${this.$store.getters['User/getLanguage']}`, {
          method: "GET",
          credentials: "include",
        })
          .then((result) => result.json())
          .then((response) => {
            if (response !== undefined) {
              if (response.status != 200) {
                if (response.status == 401) {
                  if (
                    response.message == "Sorry, Your membership has expired"
                  ) {
                    this.$store.commit("User/setSubscription", newSubs[0]);
                    this.$router.push({ name: "payment" });
                  } else if (response.message === "Please Login") {
                    this.$store.commit("global/setSnackbar", {
                      message: response.message,
                    });
                    this.$router.push({ name: "Login" });
                  }
                } else {
                  return this.$store.commit("global/setSnackbar", {
                    message: response.message,
                  });
                }
              } else {
                return this.$store.commit("global/setSnackbar", {
                  message: this.$t("ProductUsage.alreadyown"),
                });
              }
            } else {
              return this.$store.commit("global/setSnackbar", {
                message: this.$t("ProductUsage.unable_find"),
              });
            }
          })
          .catch((err) => {
            
            return this.$store.commit("global/setSnackbar", { message: err });
          });
        this.DOMElements.next.loading = false;
      } else {
        if (newSubs[0] === undefined || newSubs[0] === null) {
          return this.$store.commit("global/setSnackbar", {
            message: this.$t("ProductUsage.invalid_sub"),
          });
        }
        if (newSubs[0].value === undefined || newSubs[0].value === null) {
          return this.$store.commit("global/setSnackbar", {
            message: this.$t("ProductUsage.invalid_sub"),
          });
        }


        // We run the mutation process
        this.DOMElements.next.loading = true;
        await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/membershipMutation?lang=${this.$store.getters['User/getLanguage']}`,
          {
            method: "POST",
            body: JSON.stringify({
              new: newSubs[0].value,
            }),
            headers:{
              'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
            }
          })
          .then(async result => {
            this.vselect = true;
            this.show = false;
            this.DOMElements.next.loading = false;
            
            if (result.errorFlag == true)
            {
              return this.$store.commit("global/setSnackbar", {
                message: result.message,
              });
            }

            this.initialize();
          
            if (result.type == 'downgrade'){
              let now = new Date();
              let nextMonth = new Date;
              if (now.getMonth() == 11) {
                nextMonth = new Date(now.getFullYear() + 1, 0, 1);
              } else {
                nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
              } 
                this.ShowFooter();
                return this.$store.commit("global/setSnackbar", {
                message: this.$t("ProductUsage.message_date") +`${("0" + (nextMonth.getMonth() + 1)).slice(-2)}.${nextMonth.getFullYear().toString().substr(-2)}.` + this.$t("ProductUsage.message_date2"),
                timeout: 5000
              });
            }
            else //upgrade membership
            {
              this.QrInfo["membershipPrice"] = (result.newPrice / 100).toFixed(2);
              this.ShowFooter();
              this.$store.commit('User/setSubscriptionName',SelectedSubscription);
              return this.$store.commit("global/setSnackbar", {
                message: this.$t("ProductUsage.message_upgrade"), 
                timeout: 5000
              });
            }
          })
          .catch(this.$handleCatch)
      }
    },
    
    setYears() {
      // console.log(this.monthsValue);
    },
    setMonths() {
      // console.log(this.monthsValue);
      //  console.log(this.yearsValue);
    },
    CloseInvoiceD(){
      this.showTable = false;
    },
    closeJournalDialog(){
      this.showTableJournal = false;
    },
    OpenInvoiceD(){
      this.btnShow=true;
      this.datepickers.calendardialog2=false
      if(!this.picker2){
        return this.$store.commit("global/setSnackbar", { message: "please select a date" });
      }
      let newDate= this.picker2.split("-")
      if(newDate.length < 2){
         return this.$store.commit("global/setSnackbar", { message: this.$t("snack_confirm.invalid_inputs") });
      }
      this.YearInvoice = `${Number(newDate[0])}`
      this.MonthInvoice = `${Number(newDate[1])}`
    },
    changeCalendar(){
      const calendaryear = this.picker;
      const getyear=calendaryear.slice(0,4);
      const mes = this.picker;
      const getmonth= parseInt(mes.slice(5,7));
      const date= new Date(getyear,getmonth-1);
      this.date = date;
      this.getKeyFigures();      
    },
    goPrevious() {
      this.dateAux = this.date.toISOString().substr(0, 7)//the date is formatted as year and month YYYY-MM
      let calendarYear =  this.dateAux;     //is saved yyy-mm en calendaryeaar
      let getYear = calendarYear.slice(0,4);// the pure year is obtained YYYY
      let  mes = this.dateAux; // is saved in month format YYYY-MM
      let getmonth = parseInt(mes.slice(5,7)-1) //the pure month MM is obtained and converted to an integer M and subtracted by 1
      const Aux = new Date(getYear,getmonth-1); // the new date is saved in Aux 
      this.date = Aux; // date is changed to match the Aux date
      this.getKeyFigures();
    },
    goNext() {
      this.dateAux = this.date.toISOString().substr(0,7)  //2021-07
      let calendarYear = this.dateAux; // 2021-07
      let getYear = calendarYear.slice(0,4);  //2021
      let mes = this.dateAux; //2021-07
      let getmonth = parseInt(mes.slice(5,7))+1; // 07 + 1  = 8
      const Aux = new Date(getYear,getmonth-1); //2021 - 7
      this.date= Aux;
      const today = new Date()
        if( today < this.date ){
          this.date.setMonth(this.date.getMonth() - 1);
          return this.$store.commit("global/setSnackbar", {
              message: this.$t("ProductUsage.notable_months"),
          });
        }
        else {
           // this.date.setMonth(this.date.getMonth() + 1);
          this.getKeyFigures();
        }
    },

    async getKeyFigures()
    {
      const datasd = {
        date: this.date,
      };

      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/keyFigures`, {
        method: "POST",
        body: JSON.stringify(datasd),
        headers:{
          'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
        }
      })
      .then(response => {
        this.QrInfo["invoicesGenerated"] = response.invoicesGenerated || "0";
        this.QrInfo["properBills"] = response.properBills || "0";
        this.QrInfo["firstReminder"] = response.firstReminder || "0";
        this.QrInfo["secondReminder"] = response.secondReminder || "0";
        this.QrInfo["remainingBalance"] = response.remainingBalance || "0";
        this.QrInfo["sentInvoices"] = response.sentInvoices || "";
        this.QrInfo["paidBills"] = response.paidBills || "0";
        this.QrInfo["totalAmount"] = response.totalAmount || "";
        this.QrInfo["paidByIt"] = response.paidByIt || "0.00";
        this.QrInfo["toBePaid"] = response.toBePaid || "0.00";
      })
      .catch(this.$handleCatch);
    },

    async initialize() {
      const datasd = {
        date: this.date,
      };

      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/productUsage`, {
        method: "POST",
        body: JSON.stringify(datasd),
        headers:{
          'X-CSRF-Token':`${this.$store.getters['global/_csrf']}`
        }
      })
      .then((response) => {
        if(response){
          const eBillAuxx = `${response.eBillQuantity} /CHF ${response.eBillPrice}`;
          const printAuxx = `${response.printQuantity} /CHF ${response.printPrice}`;
          const extraBillsAuxx = `${response.extraBills} /CHF ${response.extraBillsPrice}`;
          this.QrInfo["membershipName"] = response.membershipName || "";
          this.QrInfo["membershipPrice"] = response.membershipPrice || "";
          this.QrInfo["totalOrders"] = response.prints ? `${response.prints.count} / ${(response.prints.total / 100).toFixed(2)} CHF` : "0 / 0.00 CHF";
          this.QrInfo["extraBillsAux"] = extraBillsAuxx || "";
          this.QrInfo["printAux"] = printAuxx || "";
          this.QrInfo["eBillAux"] = eBillAuxx || "";
          this.QrInfo["totalPriceMonth"] = response.totalPriceMonth || "";
          this.QrInfo["invoicesGenerated"] = response.invoicesGenerated || "0";
          this.QrInfo["properBills"] = response.properBills || "0";
          this.QrInfo["firstReminder"] = response.firstReminder || "0";
          this.QrInfo["secondReminder"] = response.secondReminder || "0";
          this.QrInfo["remainingBalance"] = response.remainingBalance || "0";
          this.QrInfo["sentInvoices"] = response.sentInvoices || "";
          this.QrInfo["paidBills"] = response.paidBills || "0";
          this.QrInfo["totalAmount"] = response.totalAmount || "";
          this.QrInfo["paidByIt"] = response.paidByIt || "0.00";
          this.QrInfo["toBePaid"] = response.toBePaid || "0.00";
          this.QrInfo["accountBills"] = response.accountBills;
          response.availableMemberships.forEach((entry) => {
            this.memberships.push({
              name: entry.name,
              value: entry.id,
            });
          });
          const set = new Set();
          response.accountBills.forEach((element) => {
            const year1 = new Date(element.creation_date);
            let months1 = new Date(element.creation_date);
            let monthstring = months1.getMonth();
            set.has(year1.getFullYear());
            if (set.has(year1.getFullYear()) == false) {
              set.add(year1.getFullYear());
              this.years.push({
                type: year1.getFullYear(),
                value: year1.getFullYear(),
              });
            }
          });
          this.ShowFooter();
        }
      })
      .catch(this.$handleCatch)
    },

    async showInvoices() {
      this.render++;
      this.showTable = true;
     
    },

    async showJournals(){
       this.render++;
       this.showTableJournal = true;
    },

    // Method that obtains the pdf that is selected in the table and downloads it
    pdfItem(item) {
      // Request that brings the selected PDF from the server
      fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/bill/download?file=${item.name}`,
        {
          method: "GET",
          credentials: "include",
        }
      )
        .then((result) => {
          // We return the request of the server in blob for its respective download
          if(result.status == 200) {
            return result.blob();
          }

          if(result.status == 401) {
            return this.$router.push({name:'Login'});
          }
        })
        // Now we get the answer in the response variable
        .then((response) => {
          // We verify that the information in the variable is not null or undefined
          if (response !== null && response !== undefined) {
            // We declare a url variable which contains a link
            var url = window.URL.createObjectURL(response);
            // now we create an html link as if it were an <a> </a>
            var a = document.createElement("a");
            // in this case we pass it to the href the address of the file obtained
            a.href = url;
            // Now we create a download object with just the name of the pdf file
            a.download = item.name.substring(0, item.name.length);
            // An element is created in the body of the page for its respective download
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            // The click element is created for when this method is used
            a.click();
            // Now we remove the created tag
            a.remove();
          } else {
            // Message that is sent if the response variable is undefined or null
            return this.$store.commit("global/setSnackbar", {
              message: this.$t("ProductUsage.server_no_respond"),
            });
          }
        })
        // Catch that is created when there is an error in the fetch
        .catch((err) => {
          // Message that is sent to indicate the error in the fetch
          return this.$store.commit("global/setSnackbar", {
            message: err,
          });
        });
    },

    async deactivate()
    {
      this.DOMElements.deactivate.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/user/deactivate?lang=${this.$store.getters['User/getLanguage']}`)
      .then(result => {
        if(result.success == "true") {
          this.deleteconfirmation3 = true;
        }
        this.deleteconfirmation2 = false;
        this.deleteconfirmation = false;
        return this.$store.commit("global/setSnackbar", {
            message: result.message,
        });
      })
      .catch(this.$handleCatch)
      this.DOMElements.deactivate.loading = false;
    }
  },
};
</script>


<style>

 .container-statements-btn{
      min-width:150px !important;
 }

</style>