<template>
  <div class="pa-0">
    <v-row>
      <v-card class="pt-0" flat>
        <v-card-title class="pt-0 pb-0">{{
          $t("Admin.Users.users")
        }}</v-card-title>
        <v-data-table
          :no-data-text="$t('qrGenerator.productsTable.data_available')"
          dense
          :headers="headers.headers"
          :items="items"
          :search="search"
          :loading="DOMElements.loadUserTableData.loading"
          :loading-text="$t('CreditorFiles.loading')"
          multi-sort
          :custom-sort="SortUsers"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
          }"
          calculate-widths
        >
          <template v-slot:top>
            <v-toolbar class="justify-end pt-0" dense flat color="white">
              <v-toolbar-title>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  :label="$t('Admin.Users.search')"
                  single-line
                  hide-details
                  class="pt-0"
                ></v-text-field>
              </v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog
                persistent
                v-model="showMasterDialog"
                :width="calculateWidth"
              >
                <template v-slot:activator="{ on }">
                  <v-col class="pl-1" cols="3">
                    <v-overflow-btn
                      segmented
                      overflow
                      dense
                      :label="$t('Admin.Users.actions')"
                      class="mb-2"
                      :items="headers.actionButtonItems"
                      item-text="text"
                      :loading="enableActionBtnLoading"
                    ></v-overflow-btn>
                  </v-col>
                </template>
                <v-card :width="calculateWidth">
                  <v-card-title>{{ setMasterDialogTitle }}</v-card-title>
                  <v-card-text class="pb-0">
                    <!-- USER STATUS LOGS !-->
                    <div v-if="showULogsDialog">
                      <v-row>
                        <v-col cols="12" class="pb-0">
                          <v-data-table
                            :headers="headers.userStatusHeaders"
                            :items="userStatusItems"
                            :loading="loadUserLog"
                            multi-sort
                            :no-data-text="
                              $t('qrGenerator.productsTable.data_available')
                            "
                          ></v-data-table>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- CARD DATA !-->
                    <div v-if="enableCardDialog">
                      <div v-if="showCardData">
                        <h3>{{ $t("Admin.Users.card_data.card_number") }}:</h3>
                        <p>{{ cardNumber }}</p>
                        <h3>{{ $t("Admin.Users.card_data.card_holder") }}:</h3>
                        <p>{{ cardHolder }}</p>
                        <h3>{{ $t("Admin.Users.card_data.card_year") }}:</h3>
                        <p>{{ cardYear }}</p>
                        <h3>{{ $t("Admin.Users.card_data.card_month") }}:</h3>
                        <p>{{ cardMonth }}</p>
                        <h3>{{ $t("Admin.Users.card_data.card_brand") }}:</h3>
                        <p>{{ cardBrandName }}</p>
                        <h3>{{ $t("Admin.Users.card_data.country_code") }}:</h3>
                        <p>{{ cardCountyCode }}</p>
                      </div>
                      <div v-else>
                        <h3>
                          {{ $t("qrGenerator.productsTable.data_available") }}
                        </h3>
                      </div>
                    </div>
                    <!-- EDIT MEMBERSHIP !-->
                    <div v-if="enableMembershipDialog">
                      <v-form ref="membershipForm">
                        <v-row>
                          <v-col cols="5">
                            <v-select
                              :no-data-text="
                                $t('qrGenerator.productsTable.data_available')
                              "
                              outlined
                              dense
                              :label="$t('Admin.Users.membershipp')"
                              item-text="name"
                              item-value="id"
                              :items="membershipItems"
                              v-model="selectedMembership"
                              @change="setNewMembershipData()"
                              :loading="membershipLoading"
                              disabled
                            ></v-select>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              outlined
                              dense
                              :label="$t('Admin.Users.number_of_invoices')"
                              v-model="membershipEditItem.number_credits"
                              :rules="[rules.isGIZero]"
                              type="number"
                              :loading="membershipLoading"
                              validate-on-blur
                              @change="userChangeInput"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5">
                            <v-text-field
                              outlined
                              dense
                              :label="$t('Admin.Users.price')"
                              type="number"
                              :rules="[rules.isGIZero]"
                              v-model="membershipEditItem.price"
                              :loading="membershipLoading"
                              validate-on-blur
                              @change="userChangeInput"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5">
                            <v-text-field
                              :label="$t('Admin.Users.number_profiles')"
                              outlined
                              dense
                              type="number"
                              :loading="membershipLoading"
                              v-model=" membershipEditItem.profiles "
                              @change="userChangeInput"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5 pb-0">
                            <v-text-field
                              class="pb-0"
                              :label="$t('Admin.Users.number_templates')"
                              outlined
                              dense
                              type="number"
                              :loading="membershipLoading"
                              :disabled="unlimitedTemplates"
                              v-model=" membershipEditItem.templates "
                              @change="userChangeInput"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col cols="5 pb-0">
                            <v-checkbox
                              class="pt-0 mt-0"
                              v-model="unlimitedTemplates"
                              :label="$t('Admin.Users.unlimited_templates')"
                              @change="userChangeInput"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5 pb-0">
                            <v-text-field
                              :label="$t('Admin.Users.treshhold_price')"
                              outlined
                              dense
                              type="number"
                              :loading="membershipLoading"
                              v-model=" membershipEditItem.SUTP "
                              @change="userChangeInput"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="5 pb-0">
                            <v-text-field
                              :label="$t('Admin.Users.saturated_price')"
                              outlined
                              dense
                              type="number"
                              :loading="membershipLoading"
                              v-model=" membershipEditItem.SUSP "
                              @change="userChangeInput"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>

                          <v-col  cols="5 pb-0"  class="pt-0 pb-0">
                            <v-checkbox
                              v-model="specialPriceCheckbox"
                              @change="specialPriceChange"
                              :label=" $t('Admin.Users.special_price') "
                            ></v-checkbox>
                          </v-col>

                          <!-- v-if="showPartnerName" -->
                          <v-col  cols="5 pb-0"  class="pt-0 pb-0">
                                <div>
                                    <div class="d-flex align-center">
                                        <h2 class="text-h6 mb-1" >{{ $t('Admin.Users.mediatedBy') }}</h2>                                   
                                      <v-btn
                                        v-if="activePartners.length < 2"
                                        elevation="2"
                                        fab
                                        icon
                                        outlined
                                        x-small
                                        class="ml-2"
                                        @click="openPartnerDialog()"
                                      >
                                        <v-icon>mdi-plus</v-icon>
                                      </v-btn>
                                    </div>
                                    <div class="d-flex flex-column align-start">
                                        <v-tooltip right v-for="(partner,index) in activePartners" :key="partner.id" >
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-chip
                                              v-bind="attrs"
                                              v-on="on"
                                              class="mb-1 text-truncate d-inline-block" 
                                              color="primary"
                                              close
                                              @click="openPartnerDialog(partner)"
                                              @click:close="deletePartner(partner)"
                                              > 
                                                <span class="wrap-text" >    
                                                   {{ partner.name  }}
                                                </span>

                                            </v-chip>
                                          </template>
                                          <span>{{ partner.model }}</span>
                                      </v-tooltip>
                                  </div>
                                </div>   
                          </v-col>

                        </v-row>
                      
                        <!-- Match partner or  -->

                      </v-form>
                    </div>

                    <!-- Pop up to change partner association to user product -->

                    <v-dialog  persistent v-model="showDialogUpdatePartner"  transition="dialog-top-transition"
                       width="500px"
                    >
                         <v-card>
                            <v-toolbar
                              :style="{
                                background: $vuetify.theme.themes.dark.green.secondary
                              }"
                              
                            >  <span class="white--text text-h5 ">  {{ titleDialogPartner }}     </span> </v-toolbar>
                             <v-card-text class="mt-4 px-3 py-2">
                                <v-select
                                :no-data-text="
                                  $t('qrGenerator.productsTable.data_available')
                                "
                                outlined
                                dense
                                label="Select Partner"
                                :items="partnersItems"
                                v-model="selectedPartner"
                                :loading="partnerLoading"
                                ></v-select>
                                <!-- Show  the type of partner -->
                                 <p v-if="selectedPartner" class="px-3 body-1">Type Partner: {{ selectedPartner.type }}  </p>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                              <v-btn
                                color="error"
                                @click="showDialogUpdatePartner = false"
                              >
                               Close
                              </v-btn>
                              <v-btn
                                color="primary"
                                @click="updatePartner">
                                   {{ textButtonDialogPartner }} 
                              </v-btn>
                            </v-card-actions>

                         </v-card>  

                   </v-dialog>





                    <!-- BILLS !-->
                    <div v-if="showBillsDialog">
                      <v-data-table
                        :no-data-text="
                          $t('qrGenerator.productsTable.data_available')
                        "
                        :headers="headers.billsHeaders"
                        :items="billsItems"
                        :loading="billsLoader"
                        dense
                        multi-sort
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-icon
                            :small="enableQRInvoiceStatusChange(item)"
                            class="mr-2"
                            @click="downloadUserBill(item)"
                            >mdi-file-pdf</v-icon
                          >
                          <template v-if="enableQRInvoiceStatusChange(item)">
                            <v-icon
                              small
                              class="mr-2"
                              @click="enableQRSubscription(item)"
                              >mdi-check</v-icon
                            >
                            <v-icon
                              small
                              class="mr-2"
                              @click="sendReminder(item)"
                            >
                              mdi-send
                            </v-icon>
                          </template>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- NEW/EDIT USER !-->
                    <div v-if="dialog">
                      <sign-up
                        ref="signUpRef"
                        :hidden_fields="hiddenFieldsArray"
                      ></sign-up>
                    </div>
                    <!-- PROFILES !-->
                    <div v-if="profileDialog">
                      <v-dialog v-model="profileCreateEditDialog" width="800px">
                        <v-card width="800px">
                          <v-card-title
                            class="mb-0 pb-0 pt-1 pr-0 pl-2 mr-0 ml-0"
                            >{{ profileFormTitle }}</v-card-title
                          >
                          <v-card-text class="mb-0 pb-0">
                            <v-form ref="profileForm">
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0">
                                  <v-text-field
                                    v-mask="'AA## #### #### #### #### #'"
                                    validate-on-blur
                                    :rules="[rules.isIBAN, rules.IBANLength]"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.iban"
                                    :label="
                                      $t(
                                        'Admin.dashboardUsers.account_numberiban'
                                      )
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0">
                                  <v-text-field
                                    v-mask="'AA## #### #### #### #### #'"
                                    validate-on-blur
                                    :rules="[rules.isQRIBAN, rules.IBANLength]"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.qr_iban"
                                    :label="
                                      $t('Admin.dashboardUsers.qriban_number')
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    v-mask="'###.###.###'"
                                    prefix="CHE-"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.uid_number"
                                    :label="
                                      $t('Admin.dashboardUsers.uid_number')
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-select
                                    :items="['CHF', 'EUR']"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.currency"
                                    :label="$t('Admin.dashboardUsers.currency')"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    validate-on-blur
                                    :rules="[
                                      rules.hasFirstName,
                                      rules.firstNameLength,
                                    ]"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.first_name"
                                    :label="
                                      $t('Admin.dashboardUsers.first_name')
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    validate-on-blur
                                    :rules="[
                                      rules.hasLastName,
                                      rules.LastNameLength,
                                    ]"
                                    dense
                                    outlined
                                    v-model="profileEditedItem.last_name"
                                    :label="
                                      $t('Admin.dashboardUsers.last_name')
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-model="profileEditedItem.street"
                                    :label="$t('Admin.dashboardUsers.street')"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-model="profileEditedItem.house_number"
                                    :label="
                                      $t('Admin.dashboardUsers.house_number')
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-model="profileEditedItem.zip_code"
                                    :label="$t('Admin.dashboardUsers.zip')"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 mp-0">
                                  <v-text-field
                                    dense
                                    outlined
                                    v-model="profileEditedItem.city"
                                    :label="$t('Admin.dashboardUsers.city')"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 pt-0 mt-0">
                                  <v-select
                                    :no-data-text="
                                      $t(
                                        'qrGenerator.productsTable.data_available'
                                      )
                                    "
                                    v-model="profileEditedItem.country"
                                    item-text="name"
                                    item-value="code"
                                    outlined
                                    dense
                                    :label="$t('Admin.dashboardUsers.country')"
                                    :items="$t('countryList')"
                                  ></v-select>
                                </v-col>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 pt-0">
                                  <v-text-field
                                    v-model="profileEditedItem.reference"
                                    dense
                                    outlined
                                    :label="
                                      $t('Admin.dashboardUsers.reference')
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="5" class="mb-0 pb-0 mt-0 pt-0">
                                  <v-checkbox
                                    v-model="profileEditedItem.default"
                                    :label="
                                      $t('Admin.dashboardUsers.default_profile')
                                    "
                                  ></v-checkbox>
                                </v-col>
                              </v-row>
                            </v-form>
                            <v-row>
                              <v-col cols="7"></v-col>
                              <v-col cols="2">
                                <v-btn
                                  @click="closeProfileCreateEditDialog()"
                                  :color="
                                    $vuetify.theme.themes.light.blue.primary
                                  "
                                  dark
                                  >{{ $t("Globals.cancel") }}</v-btn
                                >
                              </v-col>
                              <v-col cols="1">
                                <v-btn
                                  @click="createEditUserProfile()"
                                  :color="
                                    $vuetify.theme.themes.light.blue.primary
                                  "
                                  :loading="
                                    DOMElements.createEditUserProfile.loading
                                  "
                                  dark
                                  >{{ $t("Globals.save") }}</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                      <v-data-table
                        :no-data-text="
                          $t('qrGenerator.productsTable.data_available')
                        "
                        :headers="headers.profileHeaders"
                        :items="profileItems"
                        :loading="profileLoadState"
                        :loading-text="$t('CreditorFiles.loading')"
                        hide-default-footer
                        multi-sort
                      >
                        <template v-slot:item.profileActions="{ item }">
                          <v-icon
                            small
                            class="mr-2"
                            @click="profileEditItem(item)"
                          >
                            mdi-pencil
                          </v-icon>
                          <v-icon
                            small
                            class="mr-2"
                            @click="profileDeleteItem(item)"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                      </v-data-table>
                    </div>
                    <!-- LOCKED USER !-->
                    <div v-if="lockUserFlag">
                      <span>{{ $t("Admin.Users.text_locked") }}</span>
                      <v-col cols="10" offset="1">
                        <v-textarea
                          outlined
                          :label="$t('Admin.Users.blocking_reason')"
                          :rules="[rules.descriptionLength]"
                          :placeholder="$t('Admin.Users.blocking_text')"
                          v-model="lockUserDescription"
                        ></v-textarea>
                      </v-col>
                    </div>
                    <!-- PASSWORD CHANGE -->
                    <div v-if="changePasswordFlag">
                      <span>{{ $t("Admin.Users.change_password") }}</span>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            :label="$t('Admin.dashboardUsers.password_field')"
                            type="password"
                            v-model="changePasswordForm.password"
                            :rules="[rules.isRequired, rules.passwordLength]"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col>
                          <v-text-field
                            type="password"
                            :label="$t('Admin.dashboardUsers.confirm_password')"
                            v-model="changePasswordForm.password2"
                            :rules="[
                              (v) =>
                                changePasswordForm.password ===
                                  changePasswordForm.password2 ||
                                $t('rules.passwordConfirmationRule'),
                            ]"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- BALANCE !-->
                    <div v-if="balanceDialog">
                      <v-row>
                        <v-col cols="2">
                          <v-text-field
                            :label="$t('Admin.Users.total_balance')"
                            v-model="totalBalance"
                            dense
                            disabled
                          ></v-text-field>
                        </v-col>
                        <v-col cols="10">
                          <v-data-table
                            :no-data-text="
                              $t('qrGenerator.productsTable.data_available')
                            "
                            :headers="headers.balanceHeaders"
                            :items="balanceItems"
                            :loading="showBalanceLoader"
                            calculate-widths
                            dense
                            multi-sort
                          ></v-data-table>
                        </v-col>
                      </v-row>
                    </div>
                    <!-- ORDERS -->
                    <div v-if="showInvoicesDialogFlag">
                      <v-data-table
                        :items="ordersItems"
                        :headers="headers.ordersHeaders"
                        :loading="showOrdersLoader"
                        :custom-sort="ordersSort"
                        dense
                      >
                        <template v-slot:top>
                          <v-row>
                            <v-col>
                              <h2>
                                {{ $t("Admin.Users.Product_Usage.orders") }}
                              </h2>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="4" class="pt-0">
                              <v-text-field
                                disabled
                                :label="
                                  $t('Admin.Users.Product_Usage.total_invoices')
                                "
                                type="number"
                                v-model="totalOrders"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="pt-0">
                              <v-text-field
                                disabled
                                :label="
                                  $t('Admin.Users.Product_Usage.invoices')
                                "
                                type="number"
                                v-model="invoicesThreshold"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="4" class="pt-0">
                              <v-text-field
                                disabled
                                :label="
                                  $t('Admin.Users.Product_Usage.invoicesplus')
                                "
                                type="number"
                                v-model="invoicesSaturated"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </template>
                      </v-data-table>
                    </div>
                  </v-card-text>
                  <v-card-actions class="justify-end">
                    <!-- USER STATUS LOGS !-->
                    <div v-if="showULogsDialog">
                      <v-btn
                        dark
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="closeLogUserDialog()"
                        >{{ $t("Globals.cancel") }}</v-btn
                      >
                    </div>
                    <!-- CARD DATA !-->
                    <div v-if="enableCardDialog">
                      <v-btn
                        :color="$vuetify.theme.themes.light.blue.primary"
                        dark
                        @click="closeCardDialog()"
                        >{{ $t("Settings.Buttons.btn_close") }}</v-btn
                      >
                    </div>
                    <!-- EDIT MEMBERSHIP !-->
                    <div v-if="enableMembershipDialog">
                      <v-col>
                        <v-row>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="closeEditMembership()"
                              >{{ $t("Globals.cancel") }}</v-btn
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="editUserMembership()"
                              :loading="DOMElements.editUserMembership.loading"
                              >{{ $t("Globals.save") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <!-- BILLS !-->
                    <div v-if="showBillsDialog">
                      <v-btn
                        dark
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="closeBillsDialog()"
                        >{{ $t("Globals.cancel") }}</v-btn
                      >
                    </div>
                    <!-- NEW/EDIT USER !-->
                    <div v-if="dialog">
                      <v-col class="pt-0 pb-0">
                        <v-row>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="close"
                              >{{ $t("Globals.cancel") }}</v-btn
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              :color="$vuetify.theme.themes.light.blue.primary"
                              dark
                              @click="save"
                              :loading="DOMElements.save.loading"
                              >{{ $t("Globals.save") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <!-- PROFILES !-->
                    <div v-if="profileDialog">
                      <v-btn
                        dark
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="closeProfilesDialog"
                        >{{ $t("Globals.btn_close") }}</v-btn
                      >
                    </div>
                    <!-- LOCKED USER !-->
                    <div v-if="lockUserFlag">
                      <v-col class="pt-0 pb-0">
                        <v-row>
                          <v-col cols="6">
                            <v-btn
                              :color="
                                $vuetify.theme.themes.dark.green.secondary
                              "
                              dark
                              @click="
                                (lockUserConfirm = true),
                                  changeLockedStatus(lockUserItem)
                              "
                              :loading="DOMElements.lock.loading"
                              >{{ $t("Globals.confirm") }}</v-btn
                            >
                          </v-col>
                          <v-col cols="6">
                            <v-btn
                              :color="
                                $vuetify.theme.themes.dark.brown.secondary
                              "
                              dark
                              @click="closeLock()"
                              >{{ $t("Globals.cancel") }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </v-col>
                    </div>
                    <!-- PASSWORD CHANGE -->
                    <div v-if="changePasswordFlag">
                      <v-btn
                        :color="$vuetify.theme.themes.dark.brown.secondary"
                        dark
                        @click="closeChangePassword"
                        >{{ $t("Globals.cancel") }}</v-btn
                      >
                      <v-btn
                        :color="$vuetify.theme.themes.dark.brown.secondary"
                        dark
                        @click="savePassword"
                        >{{ $t("Globals.save") }}</v-btn
                      >
                    </div>
                    <!-- BALANCE !-->
                    <div v-if="balanceDialog">
                      <v-btn
                        :color="$vuetify.theme.themes.dark.brown.secondary"
                        dark
                        @click="closeBalanceDialog()"
                        >{{ $t("Globals.btn_close") }}</v-btn
                      >
                    </div>
                    <!-- ORDERS -->
                    <div v-if="showInvoicesDialogFlag">
                      <v-btn
                        dark
                        :color="$vuetify.theme.themes.light.blue.primary"
                        @click="closeInvoiceDialog()"
                        >{{ $t("Globals.btn_close") }}</v-btn
                      >
                    </div>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.balance="{ item }">
            <v-icon class="mr-2" @click="openBalanceDialog(item)"
              >mdi-currency-usd</v-icon
            >
          </template>
          <template v-slot:item.bills="{ item }">
            <v-icon small class="mr-2" @click="enableBillsDialog(item)"
              >mdi-file-document-multiple-outline</v-icon
            >
          </template>
          <template v-slot:item.recurrent="{ item }">
            <v-icon v-if="item.recurrent === true" @click="changeRCUser(item)"
              >mdi-check-outline</v-icon
            >
            <v-icon v-else @click="changeRCUser(item)"
              >mdi-close-outline</v-icon
            >
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon
              small
              class="mr-2"
              @click="deleteItem(item)"
              :loading="DOMElements.deleteItem.loading"
              v-if="false"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.activeAccount="{ item }">
            <v-icon
              v-if="item.activeAccount === `yes`"
              @click="changeStatusUser(item)"
              >mdi-account-check-outline</v-icon
            >
            <v-icon v-else-if="item.activeAccount === `pending`"
              >mdi-account-question-outline</v-icon
            >
            <v-icon
              v-else-if="item.activeAccount === `no`"
              @click="changeStatusUser(item)"
              >mdi-account-remove-outline</v-icon
            >
            <v-icon small @click="getUserStatusLog(item)"
              >mdi-layers-triple</v-icon
            >
          </template>
          <template v-slot:item.lockedAccount="{ item }">
            <v-icon
              small
              v-if="item.lockedAccount === false"
              @click="changeLockedStatus(item)"
              >mdi-lock-open-outline</v-icon
            >
            <v-icon v-else small @click="changeLockedStatus(item)"
              >mdi-lock</v-icon
            >
          </template>
          <template v-slot:item.profiles="{ item }">
            <v-icon small class="mr-2" @click="showProfilesDialog(item)"
              >mdi-account-tie</v-icon
            >
          </template>
          <template v-slot:item.membership="{ item }">
            <v-icon class="mr-2" @click="showMembershipDialog(item)"
              >mdi-trophy-award</v-icon
            >
          </template>
          <template v-slot:item.card="{ item }">
            <v-icon class="mr-2" @click="showCardDialog(item)"
              >mdi-credit-card-outline</v-icon
            >
          </template>
          <template v-slot:item.invoices="{ item }">
            <v-icon class="mr-2" small @click="showInvoicesDialog(item)"
              >mdi-file-document-multiple</v-icon
            >
          </template>
        </v-data-table>
      </v-card>
    </v-row>




  </div>
</template>

<script>
import signUp from "../../signup/signup.form";
import { mapGetters } from "vuex";
import { mask } from "vue-the-mask";
export default {
  // mixins: [rules],
  directives: {
    mask,
  },
  components: {
    "sign-up": signUp,
  },
  data() {
    return {
      totalBalance: 0,
      showInvoicesDialogFlag: false,
      ordersItems: [],
      invoicesThreshold: 0,
      invoicesSaturated: 0,
      totalOrders: 0,
      showOrdersLoader: false,
      balanceItems: [],
      showBalanceLoader: false,
      balanceDialog: false,
      showMasterDialog: false,
      enableActionBtnLoading: false,
      cardNumber: "",
      cardHolder: "",
      cardYear: "",
      cardMonth: "",
      cardBrandName: "",
      cardCountyCode: "",
      showCardData: false,
      enableCardDialog: false,
      specialPriceCheckbox: false,
      partnerName:"",
      showPartnerName:false,
      showDialogUpdatePartner:false,
      partnersItems:[],
      selectedPartner:"",
      partnerLoading:false,
      currentPartner:"",
      titleDialogPartner:"",
      textButtonDialogPartner:"",
      partners:[],
      activePartners:[],
      inputChange:false,
      unlimitedTemplates: false,
      showULogsDialog: false,
      showBillsDialog: false,
      billsLoader: false,
      membershipLoading: false,
      hiddenFieldsArray: [],
      enableMembershipDialog: false,
      membershipItems: [],
      selectedMembership: null,
      currentProfileSubsLimit: 0,
      currentUser: null,
      currentProfile: null,
      profileCreateEditDialog: false,
      search: "",
      dialog: false,
      profileDialog: false,
      profileLoadState: false,
      passwordAvailable: true,
      items: [],
      profileItems: [],
      editedIndex: -1,
      profileEditedIndex: -1,
      editedItem: {
        name: "",
        lastName: "",
        username: "",
        email: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        legal_form: "",
        legal_form_others: "",
        industry: "",
        industry_others: "",
        number_members: "",
        number_employees: "",
        UID: "",
        reference: "",
        salutation: "",
        company: "",
        payment: "",
        address_suffix: "",
        id_membership: null,
      },
      profileEditedItem: {
        iban: "",
        qr_iban: "",
        uid_number: "",
        currency: "",
        first_name: "",
        last_name: "",
        street: "",
        house_number: "",
        zip_code: "",
        city: "",
        country: "",
        reference: "",
        default: false,
      },
      membershipEditItem: {
        id: null,
        name: "",
        number_credits: 0,
        price: 0,
        templates: 0,
        profiles: 0,
        SUTP: 0,
        SUSP: 0,
      },
      defaultItem: {
        name: "",
        lastName: "",
        username: "",
        email: "",
        street: "",
        number: "",
        zip: "",
        city: "",
        country: "",
        account: "",
        legal_form: "",
        legal_form_others: "",
        industry: "",
        industry_others: "",
        number_members: "",
        number_employees: "",
        UID: "",
        reference: "",
        salutation: "",
        company: "",
        payment: "",
        address_suffix: "",
        id_membership: null,
      },
      ProfileDefaultItem: {
        iban: "",
        qr_iban: "",
        uid_number: "",
        currency: "",
        first_name: "",
        last_name: "",
        street: "",
        house_number: "",
        zip_code: "",
        city: "",
        country: "",
        default: false,
      },
      userStatusItems: [],
      billsItems: [],
      loadUserLog: false,
      lockUserFlag: false,
      lockUserItem: "",
      lockUserConfirm: false,
      changePasswordFlag: false,
      lockUserDescription: "",
      DOMElements: {
        deleteItem: {
          loading: false,
        },
        save: {
          loading: false,
        },
        createEditUserProfile: {
          loading: false,
        },
        editUserMembership: {
          loading: false,
        },
        loadUserTableData: {
          loading: false,
        },
        lock: {
          loading: false,
        },
      },
      rules: {
        isRequired: (value) => !!value || this.$t("rules.required"),
        passwordLength: (value) => {
          return String(value).length >= 8 || this.$t("rules.passwordLength");
        },
        isIBAN: (v) => {
          if (v.length >= 5) {
            return v.charAt(5) != 3 || this.$t("rules.isIBAN");
          } else {
            return true;
          }
        },
        isQRIBAN: (v) => {
          if (v.length >= 5) {
            return v.charAt(5) == 3 || this.$t("rules.isQRIBAN");
          } else {
            return true;
          }
        },
        IBANLength: (v) => {
          return v.length <= 26 || this.$t("rules.isValidQRIBAN");
        },
        hasFirstName: (v) => {
          return !!v || this.$t("rules.nameRules");
        },
        firstNameLength: (v) => {
          if (v) {
            return v.length <= 35 || this.$t("rules.name_length");
          } else {
            return true;
          }
        },
        hasLastName: (v) => {
          return !!v || this.$t("rules.isIBAN");
        },
        LastNameLength: (v) => {
          if (v) {
            return v.length <= 35 || this.$t("rules.name_length");
          } else {
            return true;
          }
        },
        isGIZero: (v) => {
          return v >= 0 || this.$t("rules.isGreater");
        },
        descriptionLength: (v) => {
          return v.length <= 65535 || this.$t("rules.invalid_length");
        },
      },
      userEdited: null,
      changePasswordForm: {
        password: "",
        password2: "",
      },
    };
  },
  computed: {
    headers() {
      return {
        headers: [
          { text: "ID", value: "id" },
          { text: this.$t("Admin.Users.active"), value: "activeAccount" },
          { text: this.$t("Admin.Users.locked"), value: "lockedAccount" },
          {
            text: this.$t("Admin.Users.profiles"),
            value: "profiles",
            sortable: false,
          },
          {
            text: this.$t("ProductUsage.main_title"),
            value: "invoices",
            sortable: false,
          },
          {
            text: this.$t("Admin.Users.bills"),
            value: "bills",
            sortable: false,
          },
          { text: this.$t("Admin.Users.name"), value: "name" },
          { text: this.$t("Admin.Users.last_name"), value: "lastName" },
          { text: this.$t("Admin.Users.email"), value: "email" },
          { text: this.$t("Admin.Users.street"), value: "street" },
          { text: this.$t("Admin.Users.house_number"), value: "number" },
          { text: this.$t("Admin.Users.zip"), value: "zip" },
          { text: this.$t("Admin.Users.city"), value: "city" },
          { text: this.$t("Admin.Users.country"), value: "country" },
          { text: this.$t("Admin.Users.creation_date"), value: "creationDate" },
          {
            text: this.$t("Admin.Users.expiration_date"),
            value: "expirationDate",
          },
          {
            text: this.$t("Admin.Users.balance"),
            value: "balance",
            sortable: false,
          },
          {
            text: this.$t("Admin.Users.membership"),
            value: `membership`,
            sortable: false,
          },
          { text: this.$t("Admin.Users.card"), value: "card", sortable: false },
          {
            text: this.$t("Admin.Users.actions"),
            value: "actions",
            sortable: false,
          },
        ],
        userStatusHeaders: [
          { text: `ID`, value: `id` },
          { text: this.$t("Admin.Users.User_Logs.subject"), value: `subject` },
          {
            text: this.$t("Admin.Users.User_Logs.description"),
            value: `description`,
          },
          {
            text: this.$t("Admin.Users.User_Logs.changed_by"),
            value: `changed_by`,
          },
          { text: this.$t("Admin.Users.User_Logs.date"), value: "date" },
        ],
        profileHeaders: [
          { text: "ID", value: "id" },
          { text: this.$t("Admin.Users.Profile.iban"), value: "iban" },
          { text: this.$t("Admin.Users.Profile.qr_iban"), value: "qr_iban" },
          {
            text: this.$t("Admin.Users.Profile.uid_number"),
            value: "uid_number",
          },
          { text: this.$t("Admin.Users.Profile.currency"), value: "currency" },
          {
            text: this.$t("Admin.Users.Profile.first_name"),
            value: "first_name",
          },
          {
            text: this.$t("Admin.Users.Profile.last_name"),
            value: "last_name",
          },
          { text: this.$t("Admin.Users.Profile.street"), value: "street" },
          {
            text: this.$t("Admin.Users.Profile.house_number"),
            value: "house_number",
          },
          { text: this.$t("Admin.Users.Profile.zip"), value: "zip_code" },
          { text: this.$t("Admin.Users.Profile.city"), value: "city" },
          { text: this.$t("Admin.Users.Profile.country"), value: "country" },
          {
            text: this.$t("Admin.Users.Profile.reference"),
            value: "reference",
          },
          {
            text: this.$t("Admin.Users.Profile.default_profile"),
            value: "default",
          },
          {
            text: this.$t("Admin.Users.Profile.actions"),
            value: "profileActions",
            sortable: false,
          },
        ],
        billsHeaders: [
          { text: `ID`, value: `id` },
          { text: this.$t("Admin.Users.Bills.file_name"), value: `fileName` },
          {
            text: this.$t("Admin.Users.Bills.custom_name"),
            value: `customName`,
          },
          {
            text: this.$t("Admin.Users.Bills.creation_date"),
            value: `creationDate`,
          },
          { text: this.$t("Admin.Users.Bills.paid"), value: `paid` },
          { text: this.$t("Admin.Users.Bills.type"), value: `type` },
          { text: this.$t("Admin.Users.Bills.disabled"), value: `disabled` },
          { text: this.$t("Admin.Users.Bills.actions"), value: `actions` },
        ],
        ordersHeaders: [
          {
            text: this.$t("Admin.Users.Product_Usage.invoices_per_order"),
            value: "used_order",
          },
          {
            text: this.$t("Admin.Users.Product_Usage.membership"),
            value: "membership_used",
          },
          {
            text: this.$t("Admin.Users.Product_Usage.creation_date"),
            value: "creation_date",
          },
        ],
        actionButtonItems: [
          {
            text: this.$t("Admin.Users.refresh"),
            callback: () => this.loadUserTableData(),
          },
          {
            text: this.$t("Admin.Users.new_user"),
            callback: () => this.openUserDialog(),
          },
          {
            text: this.$t("Admin.Users.export"),
            callback: () => this.exportUsers(),
          },
        ],
        balanceHeaders: [
          { text: "ID", value: "id" },
          { text: this.$t("Admin.Users.subject"), value: "subject" },
          { text: this.$t("Admin.Users.amount"), value: "amount" },
          { text: this.$t("Admin.Users.date"), value: "date" },
        ],
      };
    },
    setMasterDialogTitle() {
      if (this.showULogsDialog) {
        return this.$t("Admin.Users.User_Logs.title");
      } else if (this.enableCardDialog) {
        return this.$t("Admin.Users.card_data.title");
      } else if (this.enableMembershipDialog) {
        return this.$t("Admin.general_product.edit_membership");
      } else if (this.showBillsDialog) {
        return this.$t("Admin.Users.Bills.bills");
      } else if (this.dialog) {
        if (this.editedIndex === -1) {
          this.passwordAvailable = true;
          return this.$t("Admin.Users.new_user");
        } else {
          this.passwordAvailable = false;
          return "Edit User";
        }
      } else if (this.profileDialog) {
        return this.$t("Admin.Users.profiles");
      } else if (this.lockUserFlag) {
        return "Lock User Account";
      } else {
        return "";
      }
    },
    calculateWidth() {
      if (this.showULogsDialog) {
        return "";
      } else if (this.enableCardDialog) {
        return "500px";
      } else if (this.enableMembershipDialog) {
        return "500px";
      } else if (this.showBillsDialog) {
        return "";
      } else if (this.dialog) {
        return "";
      } else if (this.profileDialog) {
        return "";
      } else if (this.lockUserFlag) {
        return "";
      } else {
        return "";
      }
    },
    formTitle() {
      //This function sets the card's title when creating/editing a user
      if (this.editedIndex === -1) {
        this.passwordAvailable = true;
        return this.$t("Admin.Users.new_user");
      } else {
        this.passwordAvailable = false;
        return "Edit User";
      }
    },
    profileFormTitle() {
      if (this.profileEditedIndex === -1) {
        return "New Profile";
      } else {
        return "Edit Profile";
      }
    },
    ...mapGetters({
      user: "User/user",
    }),
  },
  mounted() {
    this.loadUserTableData();
  },
  destroyed() {
    this.user.email = "";
    this.user.email2 = "";
    this.user.name = "";
    this.user.lastname = "";
    this.user.street = "";
    this.user.number = "";
    this.user.zip = "";
    this.user.city = "";
    this.user.country = "";
    this.user.legalFormSelected = "";
    this.user.legalFormOthersText = "";
    this.user.industrySelected = "";
    this.user.industryOthersText = "";
    this.user.numberMembersSelected = "";
    this.user.UIDText = "";
    this.user.referenceText = "";
    this.user.salutationText = "";
    this.user.companyText = "";
    this.user.paymentSelected = "";
    this.user.addressSuffixText = "";
    this.user.numberEmployeesSelected = "";
    this.user.password = "";
    this.user.password2 = "";
  },
  methods: {
    SortUsers(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a, b) => {
          if (
            currentIndex === "creationDate" ||
            currentIndex === "expirationDate"
          ) {
            if (currentIsDesc)
              return (
                new Date(`${b[currentIndex]} UTC`) -
                new Date(`${a[currentIndex]} UTC`)
              );
            return (
              new Date(`${a[currentIndex]} UTC`) -
              new Date(`${b[currentIndex]} UTC`)
            );
          } else if (currentIndex == "id") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let currentA = a[currentIndex] ? a[currentIndex] : "";
            let currentB = b[currentIndex] ? b[currentIndex] : "";
            if (currentIsDesc)
              return currentA
                .toLowerCase()
                .localeCompare(currentB.toLowerCase());
            return currentB.toLowerCase().localeCompare(currentA.toLowerCase());
          }
        });
      }
      return items;
    },
    async deleteItem(item) {
      //This function deletes users form the data base
      //If confirmed by the admin, a request is send to the server through the "/api/deleteUser" endpoint
      //this request deletes the user
      const index = this.items.indexOf(item);
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to delete the user?`
        )
        .then(async (deleteUser) => {
          if (deleteUser) {
            this.DOMElements.deleteItem.loading = true;
            await this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/user`,
              {
                method: "DELETE",
                credentials: "include",
                body: JSON.stringify({
                  id: item.id,
                }),
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then((response) => {
                this.items.splice(index, 1);
                return this.$store.commit("global/setSnackbar", {
                  message: response.message,
                });
              })
              .catch(this.$handleCatch);
            this.DOMElements.deleteItem.loading = false;
          }
        });
    },
    async deletePartner(partner){
         
          const data = {
                partner:partner.id,
                user:this.currentUser,
            }

            try{
                const resultUpdateRelationPartner = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partner/user`, {
                    method: "DELETE",
                    credentials: "include", 
                    body: JSON.stringify(data),
                    headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                    },
                });

                //update the currentPartner
                if(resultUpdateRelationPartner.status == 200){
                     
                    let indexPartnerDelete = this.activePartners.findIndex((element)=> element.id == partner.id);
                    if(indexPartnerDelete != -1){
                        this.activePartners.splice(indexPartnerDelete,1);
                    } 
                }

            }catch(err){
                console.log(err);
            }        

    },



    //////////////////////////Users data table functions//////////////////////////////////
    editItem(item) {
      this.hiddenFieldsArray.push("password");
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.user.email = item.email;
      this.user.email2 = item.email;
      this.user.name = item.name;
      this.user.lastname = item.lastName;
      this.user.street = item.street;
      this.user.number = item.number;
      this.user.zip = item.zip;
      this.user.city = item.city;
      this.user.country = item.country;
      this.user.legalFormSelected = item.legal_form;
      this.user.legalFormOthersText = item.legal_form_others;
      this.user.industrySelected = item.industry;
      this.user.industryOthersText = item.industry_others;
      this.user.numberMembersSelected = item.number_members;
      this.user.UIDText = item.UID;
      this.user.referenceText = item.reference;
      this.user.salutationText = item.salutation;
      this.user.companyText = item.company;
      this.user.paymentSelected = item.payment;
      this.user.addressSuffixText = item.address_suffix;
      this.user.numberEmployeesSelected = item.number_employees;

      //Opens dialog
      this.dialog = true;
      this.showMasterDialog = true;
      this.$nextTick(() => {
        this.$refs.signUpRef.legalFormFunction();
      });
    },
    openUserDialog() {
      this.hiddenFieldsArray = new Array();
      //Opens dialog
      this.dialog = true;
      this.showMasterDialog = true;
      this.$nextTick(() => {
        this.$refs.signUpRef.legalFormFunction();
      });
    },
    openPartnerDialog(partner){

          // if value is a not null partner, the user want to change the partner
          if(partner){
              this.titleDialogPartner = this.$t("Admin.dashboardPartners.titleDialogChange");
              this.textButtonDialogPartner = "Update"
          }else{
              this.titleDialogPartner = this.$t("Admin.dashboardPartners.titleDialogAdd");
              this.textButtonDialogPartner = "Save"
          }

          this.showDialogUpdatePartner = true;
          this.currentPartner = partner;
          this.selectedPartner = partner;
          this.partnerLoading = true;
          this.getPartners();

    },
    async getPartners(){
      const { partners } = (await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partners`, {
          method: "GET",
          credentials: "include",
      })).data;
      this.partnerLoading = false;
      this.partnersItems =   partners.map( element => { return {"text":element.name , "value":element } }   );   
    },
    async updatePartner(){
      
      //if currentPartner is empty it is because a partner is going to be added and if 
      //it is not empty it should be verified if it is different from the partner to be updated.
      if(!this.currentPartner || this.currentPartner.id != this.selectedPartner){
        
            const data = {
                oldPartner:this.currentPartner ? this.currentPartner.id : null ,
                newPartner:this.selectedPartner.id,
                user:this.currentUser,
            }


            
            try{

                    const resultUpdateRelationPartner = await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/partner/user`, {
                    method: "POST",
                    credentials: "include", 
                    body: JSON.stringify(data),
                    headers: {
                    "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                    },
                })

                //update the currentPartner
                if(resultUpdateRelationPartner.status == 200){
                    
                    let indexOldPartner = -1
                    if(this.currentPartner){
                        //find the position of the previous partner before updating it 
                         indexOldPartner = this.activePartners.findIndex((element)=>{
                          return element.id == this.currentPartner.id
                        });
                    }
                    
                    //this.currentPartner = this.selectedPartner;

                    //if the index is not found it is because the user added a partner instead of updating an existing one.  
                    if(indexOldPartner != -1){
                        this.activePartners[indexOldPartner] = resultUpdateRelationPartner.partner;   
                    }else{
                        this.activePartners.push(resultUpdateRelationPartner.partner);
                    }
  
                }

            }catch(err){
                
                return this.$store.commit("global/setSnackbar", {
                  message: err.response.message,
                });
                
            }         
      }
      this.showDialogUpdatePartner = false;    
    },
    close() {
      this.dialog = false;
      this.showMasterDialog = false;
      this.hiddenFieldsArray = new Array();
      this.$nextTick(() => {
        this.user.email = "";
        this.user.email2 = "";
        this.user.name = "";
        this.user.lastname = "";
        this.user.street = "";
        this.user.number = "";
        this.user.zip = "";
        this.user.city = "";
        this.user.country = "";
        this.user.legalFormSelected = "";
        this.user.legalFormOthersText = "";
        this.user.industrySelected = "";
        this.user.industryOthersText = "";
        this.user.numberMembersSelected = "";
        this.user.UIDText = "";
        this.user.referenceText = "";
        this.user.salutationText = "";
        this.user.companyText = "";
        this.user.paymentSelected = "";
        this.user.addressSuffixText = "";
        this.user.numberEmployeesSelected = "";
        this.user.password = "";
        this.user.password2 = "";
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    /////////////////////////////////////////////////////////////////////////////////////
    async save() {
      //This function creates or edit a user
      //If the request has an ID and if it matches one on the DB
      //then a user is edited, if not then the user is created
      if (!this.$refs.signUpRef.$refs.form.validate()) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }

      let data = {
        id: this.editedItem.id ? this.editedItem.id : null,
        name: this.user.name,
        last_name: this.user.lastname,
        email: this.user.email,
        address_suffix: this.user.addressSuffixText,
        street: this.user.street,
        number: this.user.number,
        zip: this.user.zip,
        city: this.user.city,
        country: this.user.country,
        legal_form: this.user.legalFormSelected,
        legal_form_others: this.user.legalFormOthersText,
        industry: this.user.industrySelected,
        industry_others: this.user.industryOthersText,
        number_members: this.user.numberMembersSelected,
        number_employees: this.user.numberEmployeesSelected,
        UID: this.user.UIDText,
        reference: this.user.referenceText,
        salutation: this.user.salutationText,
        company: this.user.companyText,
        payment: this.user.paymentSelected,
      };

      if (!this.hiddenFieldsArray.includes("password")) {
        data["password"] = this.user.password;
      }
      this.DOMElements.save.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/user`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(data),
        headers: {
          "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
        },
      })
        .then((response) => {
          if (response) {
            if (this.editedIndex > -1) {
              Object.assign(this.items[this.editedIndex], {
                name: data.name,
                lastName: data.last_name,
                username: data.email,
                email: data.email,
                street: data.street,
                number: data.number,
                zip: data.zip,
                city: data.city,
                country: data.country,
                legal_form: data.legal_form,
                legal_form_others: data.legal_form_others,
                industry: data.industry,
                industry_others: data.industry_others,
                number_members: data.number_members,
                number_employees: data.number_employees,
                UID: data.UID,
                reference: data.reference,
                salutation: data.salutation,
                company: data.company,
                payment: data.payment,
                address_suffix: data.address_suffix,
              });
            } else {
              let newEDate = new Date(response.creditor.expired_date);
              let edate = `${newEDate.getDate()}/${
                newEDate.getMonth() + 1
              }/${newEDate.getFullYear()}`;
              let newCDate = new Date(response.creditor.creation_date);
              let cdate = `${newCDate.getDate()}/${
                newCDate.getMonth() + 1
              }/${newCDate.getFullYear()}`;

              this.items.push({
                id: response.user.id,
                name: response.user.name,
                lastName: response.user.last_name,
                username: response.user.email,
                email: response.user.email,
                street: response.user.street,
                number: response.user.number,
                zip: response.user.zip,
                city: response.user.city,
                country: response.user.country,
                legal_form: response.user.legal_form,
                legal_form_others: response.user.legal_form_others,
                industry: response.user.industry,
                industry_others: response.user.industry_others,
                number_members: response.user.number_members,
                number_employees: response.user.number_employees,
                UID: response.user.UID,
                reference: response.user.reference,
                salutation: response.user.salutation,
                company: response.user.company,
                payment: response.user.payment,
                address_suffix: response.user.address_suffix,
                id_membership: response.creditor.id_membership,
                creationDate: cdate,
                expirationDate: edate,
                activeAccount: response.creditor.active_account || "",
                lockedAccount:
                  response.creditor.active_account == "locked" ? true : false,
              });
            }
            this.$store.commit("global/setSnackbar", {
              message: response.message,
            });
            return this.close();
          }
        })
        .catch(this.$handleCatch);
      this.DOMElements.save.loading = false;
    },
    async changeStatusUser(user) {
      let index = this.items.indexOf(user);
      if (index >= 0) {
        let currentState = this.items[index].activeAccount;
        let nextState = currentState === `yes` ? false : true;
        await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/user/status`,
          {
            method: `POST`,
            credentials: `include`,
            body: JSON.stringify({
              id: user.id,
              updateActive: nextState,
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        )
          .then((response) => {
            if (response) {
              this.items[index].activeAccount = nextState ? "yes" : "no";
              this.items[index].lockedAccount =
                nextState && this.items[index].lockedAccount
                  ? false
                  : this.items[index].lockedAccount;
              return this.$store.commit(`global/setSnackbar`, {
                message: response.message,
              });
            }
          })
          .catch(this.$handleCatch);
      } else {
        return this.$store.commit(`global/setSnackbar`, {
          message: `User not found please reload the page`,
        });
      }
    },

    async changeLockedStatus(user) {
      let index = this.items.indexOf(user);
      if (index >= 0) {
        let currentState = this.items[index].lockedAccount;
        if (currentState == true || this.lockUserConfirm == true) {
          if (this.lockUserDescription.length > 65535) {
            return this.$store.commit(`global/setSnackbar`, {
              message: this.$t("rules.invalid_length"),
            });
          }
          this.DOMElements.lock.loading = true;
          let nextState = currentState === true ? false : true;
          await this.$fetch(
            `${process.env.VUE_APP_SERVER_URL}/api/admin/user/locked`,
            {
              method: `POST`,
              credentials: `include`,
              body: JSON.stringify({
                id: user.id,
                updateLocked: nextState,
                description: this.lockUserDescription,
              }),
              headers: {
                "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
              },
            }
          )
            .then((response) => {
              this.lockUserFlag = false;
              this.showMasterDialog = false;
              this.lockUserItem = "";
              this.lockUserDescription = "";
              this.lockUserConfirm = false;
              this.DOMElements.lock.loading = false;
              if (response) {
                this.items[index].lockedAccount = nextState ? true : false;
                this.items[index].activeAccount = this.items[index]
                  .lockedAccount
                  ? "fuk"
                  : "yes";
              }
            })
            .catch((err) => {
              this.DOMElements.lock.loading = false;
              this.$handleCatch(err);
            });
        } else {
          this.lockUserItem = user;
          this.lockUserFlag = true;
          this.showMasterDialog = true;
        }
      } else {
        return this.$store.commit(`global/setSnackbar`, {
          message: `User not found please reload the page`,
        });
      }
    },

    async changeUserPassword(item) {
      this.userEdited = item;
      this.showMasterDialog = true;
      this.passwordAccount = true;
      this.changePasswordFlag = true;
    },

    closeLock() {
      this.lockUserFlag = false;
      this.showMasterDialog = false;
      this.lockUserItem = "";
      this.lockUserDescription = "";
      this.lockUserConfirm = false;
    },

    async showProfilesDialog(user) {
      this.profileDialog = true;
      this.showMasterDialog = true;
      this.profileLoadState = true;
      this.profileItems = new Array();
      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/profiles?user=${user.id}`
      )
        .then(async (response) => {
          if (response) {
            this.currentProfileSubsLimit = response.subscriptionLimit;
            if (response.profiles.length > 0) {
              this.profileItems = new Array();
              for (let i = 0; i < response.profiles.length; i++) {
                await this.profileItems.push({
                  id: response.profiles[i].id,
                  iban: response.profiles[i].iban,
                  qr_iban: response.profiles[i].qr_iban,
                  uid_number: response.profiles[i].uid_number,
                  currency: response.profiles[i].currency,
                  first_name: response.profiles[i].first_name,
                  last_name: response.profiles[i].last_name,
                  street: response.profiles[i].street,
                  house_number: response.profiles[i].house_number,
                  zip_code: response.profiles[i].zip_code,
                  city: response.profiles[i].city,
                  country: response.profiles[i].country,
                  reference: response.profiles[i].reference,
                  default: response.profiles[i].default,
                });
              }
            }
            this.currentUser = user.id;
            this.profileLoadState = false;
            return this.$store.commit(`global/setSnackbar`, {
              message: response.message,
            });
          }
        })
        .catch((err) => {
          this.currentUser = null;
          this.profileLoadState = false;
          this.$handleCatch(err);
        });
    },
    closeProfilesDialog() {
      this.profileDialog = false;
      this.showMasterDialog = false;
      this.profileItems = new Array();
      this.currentUser = null;
    },
    profileEditItem(profile) {
      this.currentProfile = profile.id;
      this.profileEditedIndex = this.profileItems.indexOf(profile);
      this.profileEditedItem = Object.assign({}, profile);
      this.profileCreateEditDialog = true;
    },
    closeProfileCreateEditDialog() {
      this.profileCreateEditDialog = false;
      this.$nextTick(() => {
        this.profileEditedItem = Object.assign({}, this.ProfileDefaultItem);
        this.profileEditedIndex = -1;
        this.currentProfile = null;
      });
    },
    async createEditUserProfile() {
      if (!this.$refs.profileForm.validate()) {
        return this.$store.commit(`global/setSnackbar`, {
          message: this.$t("snack_confirm.invalid_inputs"),
        });
      }
      if (this.currentProfile === null) {
        if (this.profileItems.length >= this.currentProfileSubsLimit) {
          return this.$store.commit(`global/setSnackbar`, {
            message: `Profile limit Reached`,
          });
        }
      }
      this.DOMElements.createEditUserProfile.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/profile`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          user: this.currentUser,
          profile: this.profileEditedItem,
        }),
        headers: {
          "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
        },
      })
        .then(async (response) => {
          if (response) {
            if (this.profileEditedItem.default === true) {
              for (let i = 0; i < this.profileItems.length; i++) {
                this.profileItems[i].default = false;
              }
            }
            if (this.profileEditedIndex > -1) {
              Object.assign(
                this.profileItems[this.profileEditedIndex],
                this.profileEditedItem
              );
            } else {
              this.profileItems.push(this.profileEditedItem);
              let tempIndex = this.profileItems.indexOf(this.profileEditedItem);
              if (tempIndex > -1) {
                this.profileItems[tempIndex].id = response.profile;
              }
            }
            this.closeProfileCreateEditDialog();
          }
        })
        .catch(this.$handleCatch);
      this.DOMElements.createEditUserProfile.loading = false;
    },
    async createNewProfile() {
      this.currentProfile = null;
      this.profileCreateEditDialog = true;
    },
    profileDeleteItem(profile) {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          this.$t("MasterData.ask_delete"),
          { color: `red` }
        )
        .then((responseFlag) => {
          if (responseFlag) {
            this.profileLoadState = true;
            this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/profile`, {
              method: "DELETE",
              credentials: "include",
              body: JSON.stringify({
                user: this.currentUser,
                profile: profile.id,
              }),
              headers: {
                "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
              },
            })
              .then((response) => {
                if (response) {
                  let profileIndex = this.profileItems.indexOf(profile);
                  this.profileItems.splice(profileIndex, 1);
                }
              })
              .catch((err) => {
                this.profileLoadState = false;
                this.$handleCatch(err);
              });
          }
        });
    },
    async showMembershipDialog(item) {
      //Sets the field to valueless
      this.selectedMembership = "";
      this.membershipEditItem.id = "";
      this.membershipEditItem.duration_time = "";
      this.membershipEditItem.name = "";
      this.membershipEditItem.number_credits = 0;
      this.membershipEditItem.price = 0;
      this.membershipEditItem.templates = 0;
      this.membershipEditItem.users = 0;
      this.partners = [];
      this.activePartners = [];
      this.showPartnerName = false;
      this.specialPriceCheckbox = false;
      
      //Starts the process
      this.currentUser = item.id;
      this.enableMembershipDialog = true;
      this.showMasterDialog = true;
      this.membershipLoading = true;
      this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/membership?user=${this.currentUser}`
      )
        .then(async (response) => {
          if (response) {
               
               //verificar si tiene un partner asociado y mostrarlo 
               if( response.partner.length > 0){
                  
                  this.partners = response.partner;
                  this.showPartnerName = true;
                  this.activePartners = response.partner;
                  
               }    
               
            
            if (response.membership) {

              let matechedMembership = await this.membershipItems.find(
                (element) => element.id == response.membership.id_membership
              );

              if (matechedMembership) {
             
                this.selectedMembership = matechedMembership.id;
                this.membershipEditItem.id = matechedMembership.id;
                this.membershipEditItem.name = matechedMembership.name;
                this.membershipEditItem.number_credits =
                  response.membership.credits;
                this.membershipEditItem.price = response.membership.price / 100;
                this.membershipEditItem.templates =
                  response.membership.templates;
                this.membershipEditItem.profiles = response.membership.profiles;
                this.membershipEditItem.SUTP = response.membership.SUTP / 100;
                this.membershipEditItem.SUSP = response.membership.SUSP / 100;
                this.specialPriceCheckbox = response.membership.enabled;
               
                this.unlimitedTemplates =
                  response.membership.unlimited_templates;

              } else {
                
                let matechedMembership = await this.membershipItems.find(
                  (element) => element.id == item.id_membership
                );

                if (matechedMembership) {

                  this.selectedMembership = matechedMembership.id;
                  this.membershipEditItem.id = matechedMembership.id;
                  this.membershipEditItem.name = matechedMembership.name;
                  this.membershipEditItem.number_credits =
                    matechedMembership.number_credits;
                  this.membershipEditItem.templates =
                    matechedMembership.templates;
                 
                  this.membershipEditItem.profiles =
                    matechedMembership.profiles;
                  this.membershipEditItem.SUTP =
                    matechedMembership.threshold_price / 100;
                  this.membershipEditItem.SUSP =
                    matechedMembership.saturated_price / 100;
                  this.unlimitedTemplates =
                    matechedMembership.unlimited_templates;
                  

                  //apply discount if the user is associated with a financial institution type partner
                  let hasDiscountPartner = "";
                  if(response.partner.length > 0 ){
                      hasDiscountPartner = response.partner.map( element => element.model ).includes("Discount");
                      if(hasDiscountPartner){
                          this.specialPriceCheckbox = true;
                          this.membershipEditItem.price = (matechedMembership.discount_price || 0 ) / 100;
                      }else{
                          this.membershipEditItem.price = (matechedMembership.price || 0 ) / 100;       
                      }

                  }else{
                      this.membershipEditItem.price = (matechedMembership.price || 0 )/ 100;   
                  }


                } else {
                  this.selectedMembership = "";
                  this.membershipEditItem.id = "";
                  this.membershipEditItem.name = "";
                  this.membershipEditItem.number_credits = 0;
                  this.membershipEditItem.price = 0;
                  this.membershipEditItem.templates = 0;
                  this.membershipEditItem.profiles = 0;
                  this.unlimitedTemplates = false;
                  this.membershipEditItem.threshold_price = 0;
                  this.membershipEditItem.saturated_price = 0;
                }
              }
            } else {
              let matechedMembership = await this.membershipItems.find(
                (element) => element.id == item.id_membership
              );
              if (matechedMembership) {
                this.selectedMembership = matechedMembership.id;
                this.membershipEditItem.id = matechedMembership.id;
                this.membershipEditItem.name = matechedMembership.name;
                this.membershipEditItem.number_credits =
                  matechedMembership.number_credits;
                
                //apply discount if the user is associated with a discounted partner
                let hasDiscountPartner = "";
                if(response.partner.length > 0 ){
                    hasDiscountPartner = response.partner.map( element => element.model ).includes("Discount");
                    if(hasDiscountPartner){
                        this.specialPriceCheckbox = true;
                        this.membershipEditItem.price = (matechedMembership.discount_price  || 0 ) / 100;
                    }else{
                        this.membershipEditItem.price = (matechedMembership.price  || 0 ) / 100;  
                    }
                }else{
                    this.membershipEditItem.price = (matechedMembership.price  || 0 ) / 100;   
                }
                   

                this.membershipEditItem.templates =
                  matechedMembership.templates;
                this.membershipEditItem.profiles = matechedMembership.profiles;
                
                this.unlimitedTemplates =
                  matechedMembership.unlimited_templates;
                this.membershipEditItem.SUTP =
                  matechedMembership.threshold_price / 100;
                this.membershipEditItem.SUSP =
                  matechedMembership.saturated_price / 100;

              } else {
                this.selectedMembership = "";
                this.membershipEditItem.id = "";
                this.membershipEditItem.account_type = "";
                this.membershipEditItem.name = "";
                this.membershipEditItem.number_credits = 0;
                this.membershipEditItem.price = 0;
                this.membershipEditItem.templates = 0;
                this.membershipEditItem.profiles = 0;
                this.unlimitedTemplates = false;
                this.membershipEditItem.threshold_price = 0;
                this.membershipEditItem.saturated_price = 0;
              }
            }
            return (this.membershipLoading = false);
          }
        })
        .catch((err) => {
          this.this.membershipLoading = false;
          this.$handleCatch(err);
        });
    },
    async editUserMembership() {
      if (this.enableMembershipDialog) {
        if (!this.$refs.membershipForm.validate()) {
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("snack_confirm.invalid_inputs"),
          });
        }
        this.DOMElements.editUserMembership.loading = true;
       
        await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/user/membership`,
          {
            method: "POST",
            body: JSON.stringify({
              user: this.currentUser,
              membership: this.membershipEditItem.id,
              credits: this.membershipEditItem.number_credits,
              price: this.membershipEditItem.price,
              templates: this.membershipEditItem.templates,
              profiles: this.membershipEditItem.profiles,
              SUTP: this.membershipEditItem.SUTP,
              SUSP: this.membershipEditItem.SUSP,
              unlimitedTemplates: this.unlimitedTemplates,
              specialPrice: (this.inputChange) ? this.specialPriceCheckbox : false  
            }),
            headers: {
              "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
            },
          }
        )
          .then((response) => {
            if (response) {
              this.enableMembershipDialog = false;
              this.showMasterDialog = false;
              this.loadUserTableData();
            }
          })
          .catch(this.$handleCatch);
        this.DOMElements.editUserMembership.loading = false;
      }
    },
    async setNewMembershipData() {
      
        if (this.enableMembershipDialog) {
          let matechedMembership = await this.membershipItems.find(
            (element) => element.id == this.selectedMembership
        );

        this.membershipEditItem.id = matechedMembership.id;
        this.membershipEditItem.duration_time = matechedMembership.duration_time;
        this.membershipEditItem.name = matechedMembership.name;
        this.membershipEditItem.number_credits = matechedMembership.number_credits;
        this.membershipEditItem.templates = matechedMembership.templates;
        this.membershipEditItem.users = matechedMembership.users;
        
        //check if you have a partner, in case you have one enter the discount price
        let hasDisocuntPartner = "";
        if( this.partners.length > 0 ){
            hasDisocuntPartner = this.partners.map( element => element.model ).includes("Discount");
            if(hasDisocuntPartner)
               this.membershipEditItem.price = (matechedMembership.discount_price || 0) / 100 ;
            else
               this.membershipEditItem.price = (matechedMembership.price|| 0 ) / 100;       
        }else{
           this.membershipEditItem.price =  (matechedMembership.price || 0 ) / 100 ;
        }
    
      }


      
    },
    async loadUserTableData() {
      //When the component is created loads the data from every user inside the Data Base
      this.items = new Array();
      this.DOMElements.loadUserTableData.loading = true;
      await this.$fetch(`${process.env.VUE_APP_SERVER_URL}/api/admin/users`)
        .then(async (response) => {
          if (response) {
            let usersArray = response.data.users;
            for (let i = 0; i < usersArray.length; i++) {
              let tempObj = {
                id: usersArray[i].id,
                name: usersArray[i].name || "",
                lastName: usersArray[i].last_name || "",
                username: usersArray[i].username || "",
                email: usersArray[i].email || "",
                address_suffix: usersArray[i].address_suffix || "",
                street: usersArray[i].street || "",
                number: usersArray[i].number || "",
                zip: usersArray[i].zip || "",
                city: usersArray[i].city || "",
                country: usersArray[i].country || "",
                legal_form: usersArray[i].legal_form || "",
                legal_form_others: usersArray[i].legal_form_others || "",
                industry: usersArray[i].industry || "",
                industry_others: usersArray[i].industry_others || "",
                number_members: usersArray[i].number_members || "",
                number_employees: usersArray[i].number_employees || "",
                UID: usersArray[i].UID || "",
                reference: usersArray[i].reference || "",
                salutation: usersArray[i].salutation || "",
                company: usersArray[i].company || "",
                payment: usersArray[i].payment || "",
              };
              if (usersArray[i].id_creditor) {
                let edate = "";
                if (usersArray[i].id_creditor.expired_date) {
                  let newEDate = new Date(
                    usersArray[i].id_creditor.expired_date
                  );
                  let newEDates = `${newEDate.getDate()}`.padStart(2, "0");
                  let newEdateMonth = `${newEDate.getMonth() + 1}`.padStart(
                    2,
                    "0"
                  );
                  let newEdateHours = `${newEDate.getHours()}`.padStart(2, "0");
                  let newEdateMinutes = `${newEDate.getMinutes()}`.padStart(
                    2,
                    "0"
                  );
                  edate = `${newEDates}/${newEdateMonth}/${newEDate.getFullYear()} ${newEdateHours}:${newEdateMinutes}`;
                }
                let cdate = "";
                if (usersArray[i].id_creditor.creation_date) {
                  let newCDate = new Date(
                    usersArray[i].id_creditor.creation_date
                  );
                  let newCDates = `${newCDate.getDate()}`.padStart(2, "0");
                  let newCDateMonth = `${newCDate.getMonth() + 1}`.padStart(
                    2,
                    "0"
                  );
                  let newCDateHours = `${newCDate.getHours()}`.padStart(2, "0");
                  let newCdateMinutes = `${newCDate.getMinutes()}`.padStart(
                    2,
                    "0"
                  );
                  cdate = `${newCDates}/${newCDateMonth}/${newCDate.getFullYear()} ${newCDateHours}:${newCdateMinutes}`;
                }
                tempObj["creationDate"] = cdate;
                tempObj["expirationDate"] = edate;
                tempObj["activeAccount"] =
                  usersArray[i].id_creditor.active_account || "";
                tempObj["lockedAccount"] =
                  usersArray[i].id_creditor.active_account == "locked"
                    ? true
                    : false;
                tempObj["id_membership"] =
                  usersArray[i].id_creditor.id_membership;
              }
              this.items.push(tempObj);
            }
            this.membershipItems = await [...response.data.memberships];
            return (this.loadState = false);
          }
        })
        .catch((err) => {
          this.loadState = false;
          this.$handleCatch(err);
        });
      this.DOMElements.loadUserTableData.loading = false;
    },
    enableBillsDialog(item) {
      this.billsItems = new Array();
      this.currentUser = item.id;
      this.showBillsDialog = true;
      this.showMasterDialog = true;
      this.billsLoader = true;
      this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/bills?user=${this.currentUser}`
      )
        .then(async (response) => {
          if (response) {
            let billsArray = response.bills;
            if (billsArray.length > 0) {
              for (let i = 0; i < billsArray.length; i++) {
                let auxDate = new Date(billsArray[i].creation_date);
                let cdate = `${auxDate.getDate()}/${
                  auxDate.getMonth() + 1
                }/${auxDate.getFullYear()}`;
                await this.billsItems.push({
                  id: billsArray[i].id,
                  fileName: billsArray[i].path_file_pdf,
                  customName: billsArray[i].custom_name,
                  creationDate: cdate,
                  paid: billsArray[i].paid ? billsArray[i].paid : "F",
                  type: billsArray[i].type,
                  disabled: billsArray[i].disabled ? "T" : "F",
                });
              }
            }
            return (this.billsLoader = false);
          }
        })
        .catch((err) => {
          this.billsLoader = false;
          this.$handleCatch(err);
        });
    },
    downloadUserBill(item) {
      if (this.showBillsDialog) {
        fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/user/bill?user=${this.currentUser}&bill=${item.id}`,
          {
            credentials: "include",
          }
        )
          .then((result) => {
            if (result.status == 200) {
              return result.blob();
            } else {
              return result.json();
            }
          })
          .then((response) => {
            if (response) {
              if (response.message) {
                return this.$store.commit(`global/setSnackbar`, {
                  message: response.message,
                });
              }
              let url = window.URL.createObjectURL(response);
              let a = document.createElement("a");
              a.href = url;
              a.download = `${item.fileName}`;
              document.body.appendChild(a);
              a.click();
              a.remove();
            } else {
              return this.$store.commit("global/setSnackbar", {
                message: this.$t("Globals.unable_server"),
              });
            }
          })
          .catch((err) => {
            return this.$store.commit(`global/setSnackbar`, { message: err });
          });
      }
    },
    enableQRInvoiceStatusChange(item) {
      if (item.disabled == "T") return false;
      if (item.paid == "T") return false;
      return item.type == "QR" ? true : false;
    },
    enableQRSubscription(item) {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to mark the invoice as paid?`
        )
        .then((response) => {
          if (response) {
            this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/user/bill/qr/enable`,
              {
                method: "POST",
                body: JSON.stringify({
                  bill: item.id,
                  user: this.currentUser,
                }),
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then(async (response) => {
                if (response) {
                  let billsCurrentIndex = this.billsItems.indexOf(item);
                  this.billsItems[billsCurrentIndex].paid = "T";
                  let matchedUser = await this.items.find(
                    (element) => element.id === this.currentUser
                  );
                  if (matchedUser) {
                    // let newEDate = new Date(response.newExpirationDate);
                    let currentUserIndex = this.items.indexOf(matchedUser);
                    // this.items[currentUserIndex].expirationDate = `${newEDate.getDate()}/${newEDate.getMonth() + 1}/${newEDate.getFullYear()}`;
                    this.items[currentUserIndex].id_membership =
                      response.newMembership;
                    this.items[currentUserIndex].activeAccount = "yes";
                  }
                }
              })
              .catch(this.$handleCatch);
          }
        });
    },
    changeRCUser(item) {
      this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/recurrent`,
        {
          method: "POST",
          body: JSON.stringify({
            user: item.id,
          }),
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      )
        .then(async (response) => {
          if (response) {
            if (response.status == 200) {
              let currentUserIndex = this.items.indexOf(item);
              this.items[currentUserIndex].recurrent = response.autoCharge;
            }
            return this.$store.commit(`global/setSnackbar`, {
              message: response.message,
            });
          } else {
            return this.$store.commit(`global/setSnackbar`, {
              message: this.$t("Globals.unable_server"),
            });
          }
        })
        .catch(this.$handleCatch);
    },
    getUserStatusLog(item) {
      this.userStatusItems = new Array();
      this.showULogsDialog = true;
      this.showMasterDialog = true;
      this.loadUserLog = true;
      this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/status?user=${item.id}`
      )
        .then(async (response) => {
          if (response) {
            for (let i = 0; i < response.LUS.length; i++) {
              let newDate = await new Date(response.LUS[i].date);
              let newDateFormatted = await `${newDate.getDate()}/${
                newDate.getMonth() + 1
              }/${newDate.getFullYear()} ${newDate.getHours()}:${newDate.getMinutes()}:${newDate.getSeconds()}`;
              await this.userStatusItems.push({
                id: response.LUS[i].id,
                changed_by: response.LUS[i].changed_by,
                description: response.LUS[i].description,
                date: newDateFormatted,
                subject: response.LUS[i].subject,
              });
            }
            this.loadUserLog = false;
          }
        })
        .catch((err) => {
          this.loadUserLog = false;
          this.$handleCatch(err);
        });
    },
    closeLogUserDialog() {
      this.showULogsDialog = false;
      this.showMasterDialog = false;
    },
    specialPriceChange() {
      if (!this.specialPriceCheckbox) {
        this.setNewMembershipData();
      }
    },
    sendReminder(item) {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to send a reminder for this bill?`
        )
        .then((response) => {
          if (response) {
            this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/email/reminder`,
              {
                method: "POST",
                body: JSON.stringify({
                  bill: item.id,
                  userId: this.currentUser,
                }),
                headers: {
                  "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
                },
              }
            )
              .then((response) =>
                this.$store.commit(`global/setSnackbar`, {
                  message: response.message,
                })
              )
              .catch(this.$handleCatch);
          }
        });
    },
    showCardDialog(item) {
      this.currentUser = item.id;
      this.cardNumber = "";
      this.cardHolder = "";
      this.cardYear = "";
      this.cardMonth = "";
      this.cardBrandName = "";
      this.cardCountyCode = "";
      this.enableCardDialog = true;
      this.showMasterDialog = true;
      this.showCardData = false;
      this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/card?user=${item.id}`
      )
        .then((response) => {
          if (response) {
            if (response.card) {
              this.showCardData = true;
              this.cardNumber = response.card.card_number;
              this.cardHolder = response.card.card_holder;
              this.cardYear = response.card.card_year;
              this.cardMonth = response.card.card_month;
              this.cardBrandName = response.card.card_brand_name;
              this.cardCountyCode = response.card.card_country_code;
            }
          }
        })
        .catch(this.$handleCatch);
    },
    closeCardDialog() {
      this.currentUser = null;
      this.cardNumber = "";
      this.cardHolder = "";
      this.cardYear = "";
      this.cardMonth = "";
      this.cardBrandName = "";
      this.cardCountyCode = "";
      this.enableCardDialog = false;
      this.showMasterDialog = false;
      this.showCardData = false;
    },
    exportUsers() {
      this.$root
        .$confirm(
          this.$t("Globals.confirm"),
          `Are you sure you want to export the users as a CSV file?`
        )
        .then((confirmation) => {
          if (confirmation) {
            this.enableActionBtnLoading = true;
            this.$fetch(
              `${process.env.VUE_APP_SERVER_URL}/api/admin/user/export`,
              {
                method: "GET",
                credentials: "include",
              }
            )
              .then((response) => {
                if (response) {
                  if (response.status == 200) {
                    if (response.data) {
                      let base64String = `data:text/csv;base64,${response.data}`;
                      fetch(base64String)
                        .then((resultFile) => resultFile.blob())
                        .then((responseFile) => {
                          let url = window.URL.createObjectURL(responseFile);
                          let a = document.createElement("a");
                          a.href = url;
                          a.download = `users.csv`;
                          document.body.appendChild(a);
                          a.click();
                          a.remove();
                          return (this.enableActionBtnLoading = false);
                        })
                        .catch((err) => {
                          return this.$store.commit(`global/setSnackbar`, {
                            message: err,
                          });
                        });
                    }
                  }
                } else {
                  return this.$store.commit(`global/setSnackbar`, {
                    message: this.$t("Globals.unable_server"),
                  });
                }
              })
              .catch((err) => {
                this.enableActionBtnLoading = false;
                return this.$store.commit(`global/setSnackbar`, {
                  message: err,
                });
              });
          }
        });
    },
    closeEditMembership() {
      this.showMasterDialog = false;
      this.enableMembershipDialog = false;
      this.hasCustomMemberShip = false;
    },
    closeBillsDialog() {
      this.showMasterDialog = false;
      this.showBillsDialog = false;
    },
    closeProfilesDialog() {
      this.showMasterDialog = false;
      this.profileDialog = false;
    },
    async openBalanceDialog(item) {
      this.balanceDialog = true;
      this.showMasterDialog = true;
      this.showBalanceLoader = true;
      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/user/balance?user=${item.id}`
      )
        .then((response) => {
          if (response) {
            if (response.status == 200) {
              this.totalBalance = response.total;
              for (let i = 0; i < response.data.length; i++) {
                let currentRecord = response.data[i];
                let currentRecordDate = new Date(currentRecord.entry_date);
                this.balanceItems.push({
                  id: currentRecord.id,
                  subject: currentRecord.subject,
                  amount: `${
                    currentRecord.amount !== undefined &&
                    currentRecord.amount !== null
                      ? currentRecord.amount / 100
                      : ""
                  }`,
                  date: `${currentRecordDate.getDate()}/${
                    currentRecordDate.getMonth() + 1
                  }/${currentRecordDate.getFullYear()} ${currentRecordDate.getHours()}:${currentRecordDate.getMinutes()}:${currentRecordDate.getSeconds()}`,
                });
              }
            }
          }
        })
        .catch(this.$handleCatch);
      this.showBalanceLoader = false;
    },
    closeBalanceDialog() {
      this.showMasterDialog = false;
      this.balanceDialog = false;
      this.balanceItems = new Array();
    },
    closeChangePassword() {
      this.showMasterDialog = false;
      this.passwordAccount = false;
      this.changePasswordFlag = false;

      Object.assign(this.changePasswordForm, {
        password: "",
        password2: "",
      });
    },
    async savePassword() {
      const { password, password2 } = this.changePasswordForm;

      if (!password || !password2) {
        return this.$store.commit(
          "globaL/setSnackbar",
          this.$t("snack_confirm.invalid_inputs")
        );
      }

      if (password.length < 8 || password2.length < 8) {
        return this.$store.commit(
          "global/setSnackbar",
          this.$t("rules.passwordLength")
        );
      }

      if (password != password2) {
        return this.$store.commit(
          `global/setSnackbar`,
          this.$t("Settings.new_passoword")
        );
      }

      await this.$fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/admin/change-password`,
        {
          method: "POST",
          body: JSON.stringify({
            password,
            password2,
            user_id: this.userEdited.id,
          }),
          headers: {
            "X-CSRF-Token": `${this.$store.getters["global/_csrf"]}`,
          },
        }
      )
        .then(({ message }) => {
          this.showMasterDialog = false;
          this.passwordAccount = false;
          this.changePasswordFlag = false;
          this.$store.commit("global/setSnackbar", message);
        })
        .catch(this.$handleCatch);

      Object.assign(this.changePasswordForm, {
        password: "",
        password2: "",
      });
    },
    async showInvoicesDialog(item) {
      this.ordersItems = [];
      this.totalOrders = 0;
      this.showInvoicesDialogFlag = true;
      this.showMasterDialog = true;
      this.showOrdersLoader = true;
      try {
        let response = await this.$fetch(
          `${process.env.VUE_APP_SERVER_URL}/api/admin/user/orders?user=${item.id}`
        );
        if (response) {
          if (response.status == 200) {
            for (let i = 0; i < response.data.orders.length; i++) {
              let currentOrder = response.data.orders[i];
              let currentCD = new Date(currentOrder.creation_date);
              let CDString = "";
              if (!isNaN(currentCD.getFullYear())) {
                let currentYear = `${currentCD.getFullYear()}`;
                let currentMonth = `${currentCD.getMonth() + 1}`;
                let currentDate = `${currentCD.getDate()}`;
                CDString = `${currentYear}/${currentMonth.padStart(
                  2,
                  "0"
                )}/${currentDate.padStart(2, "0")}`;
              }
              this.ordersItems.push({
                creation_date: CDString,
                membership_used: currentOrder.membership,
                used_order: currentOrder.used_credits,
              });
              this.totalOrders += currentOrder.used_credits;
            }
            this.invoicesThreshold =
              this.totalOrders <= 500 ? this.totalOrders : 500;
            this.invoicesSaturated =
              this.totalOrders <= 500 ? 0 : this.totalOrders - 500;
          } else {
            this.$store.commit(`global/setSnackbar`, {
              message: response.message,
            });
          }
          this.showOrdersLoader = false;
        } else {
          this.showOrdersLoader = false;
          return this.$store.commit(`global/setSnackbar`, {
            message: this.$t("Globals.unable_server"),
          });
        }
      } catch (err) {
        this.showOrdersLoader = false;
        return this.$store.commit(`global/setSnackbar`, { message: err });
      }
    },

    closeInvoiceDialog() {
      this.showMasterDialog = false;
      this.showInvoicesDialogFlag = false;
    },
    
    ordersSort(items, index, isDesc) {
      for (let i = 0; i < index.length; i++) {
        let currentIndex = index[i];
        let currentIsDesc = isDesc[i];
        items.sort((a, b) => {
          if (currentIndex === "creation_date") {
            if (currentIsDesc)
              return (
                new Date(`${b[currentIndex]} UTC`) -
                new Date(`${a[currentIndex]} UTC`)
              );
            return (
              new Date(`${a[currentIndex]} UTC`) -
              new Date(`${b[currentIndex]} UTC`)
            );
          } else if (currentIndex == "used_order") {
            if (currentIsDesc) return b[currentIndex] - a[currentIndex];
            return a[currentIndex] - b[currentIndex];
          } else {
            let currentA = a[currentIndex] ? a[currentIndex] : "";
            let currentB = b[currentIndex] ? b[currentIndex] : "";
            if (currentIsDesc)
              return currentA
                .toLowerCase()
                .localeCompare(currentB.toLowerCase());
            return currentB.toLowerCase().localeCompare(currentA.toLowerCase());
          }
        });
      }
      return items;
    },
    
    userChangeInput(){
      this.inputChange = true;
    }

  },
  errorCaptured(err, vm, info) {
    console.log(`${err.stack}\n\nfound in ${info} of component`);
    // return false
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
};
</script>


<style>
  .wrap-text{
    overflow:hidden ; 
    text-overflow: ellipsis ;
  }
</style>