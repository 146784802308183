/**
 * 
 * @param value without white spaces
 */
 function validateCharac(value) {
    const characters = [
        '¡',
        '¢',
        '¤',
        '¥',
        '§',
        '¨',
        '©',
        'ª',
        '«',
        '¬',
        '®',
        '¯',
        '°',
        '±',
        '²',
        '³',
        'µ',
        '·',
        '¸',
        '¹',
        'º',
        '»',
        '¼',
        '½',
        '¾',
        '¿',
        'Ã',
        'Å',
        'Æ',
        'Ð',
        'Õ',
        '×',
        'Ø',
        'Þ',
        'ã',
        'å',
        'æ',
        'ð',
        'õ',
        'ø',
        'þ',
        'ÿ',
        'ć',
        '¦',
        '¶',
        '­',
        '⌂',
        'ž'
    ];
    
    let onlySpace = /^[\s]+$/;

    // If the sendend value is an array execute the array method version
    if(Array.isArray(value)){
        //console.log("Array Version");
        for(const actualValue of value){
            if(actualValue != null || actualValue != undefined){
                for (i = 0; i < characters.length; i++) {
                    // We convert the value into a string, this is for the case that if the value we read is a number, 
                    // it does not create an error in the analyzer
                    if(actualValue.toString().includes(characters[i]) || onlySpace.test(actualValue)){
                        return true;
                    }
                }
            }
        }
        return false;

     // Else execute the one value method version
    }else{
        //console.log("Normal Version");
        if(value != null || value != undefined){
            for (i = 0; i < characters.length; i++) {
                // We convert the value into a string, this is for the case that if the value we read is a number, 
                // it does not create an error in the analyzer
                if(value.toString().includes(characters[i]) ){ // || onlySpace.test(value)
                    return true;
                }
            }
        }
        return false;
    }

}


module.exports = {
    validateCharac
}